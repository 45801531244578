import React from "react";
import CombinacionNuevoView from "./CombinacionNuevoView";
import Producto from "../../services/Producto";

let events = [];

const subscribe = (fun) => {
  events.push(fun);
};

class CombinacionNuevo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      combinacion: {
        id_combinacion: this.props.idCombinacion,
        descripcion: "",
        stock: 0,
        stock_auto: 0,
        stock_min: 0,
        atributos: [],
      },
      atributos: [],
      valores: [],
      id_atributo_select: undefined,
      id_atributo_valor_select: undefined,
    };
    this.handleChangeDescripcion = this.handleChangeDescripcion.bind(this);
    this.handleChangeStock = this.handleChangeStock.bind(this);
    this.handleChangeStockMin = this.handleChangeStockMin.bind(this);
    this.handleChangeStockAuto = this.handleChangeStockAuto.bind(this);
    this.handleChangeAtributo = this.handleChangeAtributo.bind(this);
    this.handleChangeAtributoValor = this.handleChangeAtributoValor.bind(this);
    this.handlClickAniadirPropiedad =
      this.handlClickAniadirPropiedad.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    if (this.props.idCombinacion) this.getCombinacion();
    this.getAtributos();
  }

  render() {
    return (
      <CombinacionNuevoView
        combinacion={this.state.combinacion}
        atributos={this.state.atributos}
        valores={this.state.valores}
        onChangeDescripcion={this.handleChangeDescripcion}
        onChangeStock={this.handleChangeStock}
        onChangeStockMin={this.handleChangeStockMin}
        onChangeStockAuto={this.handleChangeStockAuto}
        onChangeAtributo={this.handleChangeAtributo}
        onChangeAtributoValor={this.handleChangeAtributoValor}
        onClickAniadirPropiedad={this.handlClickAniadirPropiedad}
        onSubmit={this.handleSubmit}
      />
    );
  }

  handleChangeDescripcion(evt) {
    this.setState({
      combinacion: {
        ...this.state.combinacion,
        descripcion: evt.target.value,
      },
    });
  }

  handleChangeStock(evt) {
    this.setState({
      combinacion: {
        ...this.state.combinacion,
        stock: Number(evt.target.value),
      },
    });
  }

  handleChangeStockMin(evt) {
    this.setState({
      combinacion: {
        ...this.state.combinacion,
        stock_min: Number(evt.target.value),
      },
    });
  }

  handleChangeStockAuto(evt) {
    this.setState({
      combinacion: {
        ...this.state.combinacion,
        stock_auto: Number(evt.target.value),
      },
    });
  }

  handleChangeAtributo(id_atributo_select) {
    this.setState({ id_atributo_select }, () => {
      this.getValores();
    });
  }

  handleChangeAtributoValor(id_atributo_valor_select) {
    this.setState({ id_atributo_valor_select });
  }

  handlClickAniadirPropiedad() {
    const atributo = this.state.atributos.find(
      (elm) => elm.id_item_atributo === this.state.id_atributo_select
    );

    const valor = this.state.valores.find(
      (elm) =>
        elm.id_item_atributo_valor === this.state.id_atributo_valor_select
    );

    this.setState({
      combinacion: {
        ...this.state.combinacion,
        atributos: [
          ...this.state.combinacion.atributos,
          {
            id_item_atributo: atributo.id_item_atributo,
            id_item_atributo_valor: valor.id_item_atributo_valor,
            item_atributo: atributo.atributo,
            item_atributo_valor: valor.item_atributo_valor,
          },
        ],
      },
    });
  }

  async handleSubmit() {
    const data = await Producto.addCombinacion({
      ...this.state.combinacion,
      idItem: this.props.idItem,
    });
    this.props.onSubmit();
    events.forEach((evt) => evt());
  }

  async getAtributos() {
    const { results: atributos } = await Producto.getAtributos();
    this.setState({ atributos });
  }

  async getValores() {
    const { results: valores } = await Producto.getOneAtributo(
      this.state.id_atributo_select
    );
    this.setState({ valores });
  }

  async getCombinacion() {
    const { results } = await Producto.getCombinacion(this.props.idCombinacion);
    this.setState({
      combinacion: {
        id_combinacion: results[0].id_combinacion,
        descripcion: results[0].combinacion,
        stock: results[0].stock,
        stock_auto: results[0].stock_auto,
        stock_min: results[0].stock_min,
        atributos: results[0].id_item_atributo === null ? [] : results,
      },
    });
  }
}

export { CombinacionNuevo as default, subscribe };
