import React from "react";
import CombinacionAniadir from "./CombinacionAniadir";
import { addNotification } from "../Notifications/Notifications";
import Producto from "../../services/Producto";
import Combinacion from "../../services/Combinacion";

export default class CombinacionAniadirContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productos: [],
      grupos_productos: [],
      combinaciones: [],
      atributos: [],
      atributos_select: [],
      cantidad: 0,
      precio: 0,
      modos: [
        { id: 1, descripcion: "Producto" },
        { id: 2, descripcion: "Pack" },
      ],
      id_modo: 1,
    };
    this.handleChangeProducto = this.handleChangeProducto.bind(this);
    this.handleChangeAtributo = this.handleChangeAtributo.bind(this);
    this.handleChangeCantidad = this.handleChangeCantidad.bind(this);
    this.handleChangePrecio = this.handleChangePrecio.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeModo = this.handleChangeModo.bind(this);
  }

  render() {
    return (
      <CombinacionAniadir
        productos={this.state.productos}
        atributos={this.state.atributos}
        cantidad={this.state.cantidad}
        precio={this.state.precio}
        hiddenPrice={this.props.hiddenPrice}
        modos={this.state.modos}
        id_modo={this.state.id_modo}
        onChangeProducto={this.handleChangeProducto}
        onChangeAtributo={this.handleChangeAtributo}
        onChangeCantidad={this.handleChangeCantidad}
        onChangePrecio={this.handleChangePrecio}
        onChangeModo={this.handleChangeModo}
        onSubmit={this.handleSubmit}
      />
    );
  }

  componentDidMount() {
    this.obtenerProductos();
    this.obtenerGruposItem();
  }

  async handleSubmit() {
    const combinacion = this.buscarCombinacion();
    if (combinacion !== undefined) {
      this.props.onSubmit({
        ...combinacion,
        cantidad: this.state.cantidad,
        precio: this.state.precio,
      });
    } else {
      await addNotification(
        "No se encontro la combinación seleccionada, si es la correcta, comuniquese con el administrador del sistema",
        "bg-warning"
      );
    }
  }

  handleChangeModo(id_modo) {
    this.setState({ id_modo });
  }

  handleChangeCantidad(evt) {
    const cantidad = evt.target.value === "" ? 0 : Number(evt.target.value);
    this.setState({ cantidad });
  }

  handleChangePrecio(evt) {
    const precio = evt.target.value === "" ? 0 : Number(evt.target.value);
    this.setState({ precio });
  }

  handleChangeProducto(id_producto) {
    this.setState(
      {
        id_producto: Number(id_producto),
        atributos: [],
        atributos_select: [],
        cantidad: 0,
      },
      () => {
        this.obtenerCombinaciones(id_producto);
      }
    );
  }

  handleChangeAtributo(id_valor, str_id) {
    const id_atributo = Number(str_id.replace("attr-", ""));
    const idx = this.state.atributos_select.findIndex(
      (elm) => Number(elm.id_atributo) === id_atributo
    );

    let atributos_select = [];

    if (idx !== -1) {
      atributos_select = this.state.atributos_select;
      if (id_valor !== undefined) {
        atributos_select[idx].id_valor = id_valor;
      } else {
        atributos_select.splice(idx, 1);
      }
    } else {
      atributos_select = [
        ...this.state.atributos_select,
        {
          id_atributo,
          id_valor,
        },
      ];
    }

    this.setState({
      atributos_select,
    });
  }

  buscarCombinacion() {
    const combinaciones = [];

    if (this.state.combinaciones.find((elm) => elm.id_item_atributo === null)) {
      return this.state.combinaciones.find(
        (elm) => elm.id_item_atributo === null
      );
    }

    this.state.combinaciones.forEach((elm) => {
      const idx = combinaciones.findIndex(
        (itm) => itm.id_combinacion === elm.id_combinacion
      );
      if (idx !== -1) {
        combinaciones[idx] = {
          ...combinaciones[idx],
          atributos: [
            ...combinaciones[idx].atributos,
            elm.id_item_atributo_valor,
          ],
        };
      } else {
        combinaciones.push({
          id_combinacion: elm.id_combinacion,
          combinacion: elm.combinacion,
          atributos: [elm.id_item_atributo_valor],
        });
      }
    });

    let combinacion;

    combinaciones.forEach((elm) => {
      if (this.state.atributos_select.length === elm.atributos.length) {
        let esEsta = true;

        elm.atributos.forEach((itm) => {
          if (
            !this.state.atributos_select.find(
              (atributo) => atributo.id_valor === itm
            )
          ) {
            esEsta = false;
          }
        });

        if (esEsta) {
          combinacion = elm;
          return;
        }
      }
    });

    return combinacion;
  }

  async obtenerCombinaciones(id_producto) {
    if (id_producto !== undefined) {
      try {
        const data = await Combinacion.listByIdProducto(id_producto);
        if (data.complete) {
          const combinaciones = data.results;
          const atributos = this.generarAtributos(combinaciones);
          this.setState({ combinaciones, atributos });
        } else {
          addNotification(data.err, "bg-warning");
          this.setState({ combinaciones: [], atributos: [] });
        }
      } catch (err) {
        addNotification("Error al obtener combinaciones", "bg-danger");
      }
    } else {
      this.setState({ combinaciones: [], atributos: [] });
    }
  }

  generarAtributos(combinaciones) {
    const atributos = [];

    combinaciones.forEach((elm) => {
      const idx = atributos.findIndex(
        (itm) => elm.id_item_atributo === itm.id_atributo
      );
      if (idx !== -1) {
        const options = [
          ...atributos[idx].options,
          // {
          //   id_valor: elm.id_item_atributo_valor,
          //   valor: elm.atributo_valor_descripcion,
          // },
        ];

        if (
          !options.find((opt) => opt.id_valor === elm.id_item_atributo_valor)
        ) {
          options.push({
            id_valor: elm.id_item_atributo_valor,
            valor: elm.atributo_valor_descripcion,
          });
        }

        atributos[idx] = {
          ...atributos[idx],
          options,
        };
      } else {
        atributos.push({
          id_atributo: elm.id_item_atributo,
          atributo: elm.atributo_descripcion,
          options: [
            {
              id_valor: elm.id_item_atributo_valor,
              valor: elm.atributo_valor_descripcion,
            },
          ],
        });
      }
    });

    return atributos;
  }

  async obtenerGruposItem() {
    try {
      const data = await Producto.conjuntoList();
      if (data.complete) {
        this.setState({ grupos_productos: data.results });
      } else {
        addNotification(data.err, "bg-warning");
      }
    } catch (err) {
      addNotification("Error al obtener grupos de productos", "bg-danger");
    }
  }

  async obtenerProductos() {
    try {
      const data = await Producto.list();
      if (data.complete) {
        this.setState({ productos: data.results });
      } else {
        addNotification(data.err, "bg-warning");
      }
    } catch (err) {
      addNotification("Error al obtener productos", "bg-danger");
    }
  }
}
