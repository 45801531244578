import React from "react";
import Navbar from "./Navbar";
import { Modules } from "../../services/Modules";

export default class NavbarContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: Modules,
      active_module: "1",
      defalt_submodule: "2",
    };

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.handleSubMenuItemClick = this.handleSubMenuItemClick.bind(this);

    this.setDefaultModule();
  }

  setDefaultModule() {
    const module =
      this.state.modules[this.state.active_module].modules[
        this.state.defalt_submodule
      ];
    this.props.onChangeModule(module);
  }

  render() {
    return (
      <Navbar
        modules={this.state.modules}
        active_module={this.state.active_module}
        onMenuItemClick={this.handleMenuItemClick}
        onSubMenuItemClick={this.handleSubMenuItemClick}
      />
    );
  }

  handleMenuItemClick(evt) {
    const id_module = evt.currentTarget.getAttribute("data-id");
    this.setState({
      active_module: id_module,
    });
  }

  handleSubMenuItemClick(evt) {
    const id_module = evt.currentTarget.getAttribute("data-id");
    const module =
      this.state.modules[this.state.active_module].modules[id_module];
    this.props.onChangeModule(module);
  }
}
