import React from "react";
import Cuenta from "../../services/Cuenta";
import CuentaDetalle from "./CuentaDetalle";

export default class CuentaDetalleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: "",
      servicios: [],
      serviciosVisibles: [],
    };

    this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this);
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
  }

  render() {
    return (
      <CuentaDetalle
        servicios={this.state.serviciosVisibles}
        searchWord={this.state.searchWord}
        onChangeSearchWord={this.handleChangeSearchWord}
        onClickNuevo={this.props.onClickNuevo}
        onDoubleClickRow={this.handleDoubleClickRow}
      />
    );
  }

  componentDidMount() {
    this.listarServicios();
  }

  handleDoubleClickRow(evt) {
    const id_servicio = evt.currentTarget.getAttribute("data-id");
    this.props.onSelectServicio(id_servicio);
  }

  handleChangeSearchWord(evt) {
    this.setState(
      {
        searchWord: evt.target.value,
      },
      () => this.buscarServicios()
    );
  }

  async listarServicios() {
    const { results: servicios } = await Cuenta.serviciosList(
      this.props.id_cuenta
    );
    this.setState(
      {
        servicios,
      },
      () => this.buscarServicios()
    );
  }

  buscarServicios() {
    let serviciosVisibles = [];

    if (this.state.searchWord.trim() === "") {
      serviciosVisibles = this.state.servicios;
    } else {
      const word = this.state.searchWord.trim().toUpperCase();
      serviciosVisibles = this.state.servicios.filter(
        (servicio) => String(servicio.id_servicio) === word
      );
    }

    this.setState({
      serviciosVisibles,
    });
  }
}
