import React from 'react'
import MovimientosAlmacen from './MovimientosAlmacen'
import OrdenCompra from '../../services/OrdenCompra'
import MovimientoAlmacen from '../../services/MovimientoAlmacen'
import { addNotification } from '../../components/Notifications/Notifications'

export default class MovimientosAlmacenContainer extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            mode: 0 // 0 = sin aside, 1 = añadir movimiento aside, 2 = detalle movimiento
        }
        this.handleClickNuevo = this.handleClickNuevo.bind(this)
        this.handleSubmitAniadir = this.handleSubmitAniadir.bind(this)
        this.handleSubmitAniadirItem = this.handleSubmitAniadirItem.bind(this)
        this.handleOnSubmitDetalle = this.handleOnSubmitDetalle.bind(this)
        this.handleDoubleClickMovimientosAlmacenRow = this.handleDoubleClickMovimientosAlmacenRow.bind(this)
    }

    render(){
        return <MovimientosAlmacen 
                    mode={this.state.mode}
                    onClickNuevo={this.handleClickNuevo}
                    onSubmitAniadir={this.handleSubmitAniadir}
                    movimiento={this.state.movimiento}
                    onSubmitAniadirItem={this.handleSubmitAniadirItem}
                    onSubmitDetalle={this.handleOnSubmitDetalle} 
                    onDoubleClickMovimientosAlmacenRow={this.handleDoubleClickMovimientosAlmacenRow}/>
    }

    handleClickNuevo(){
        this.setState({ mode: 1 })
    }

    async handleOnSubmitDetalle(){
        // console.log(this.state.movimiento.items);
        const movimiento = {...this.state.movimiento, orden_compra: undefined}
        // const data = await MovimientoAlmacen.add(movimiento)
        try{
            await MovimientoAlmacen.add(movimiento)
            this.setState({
                mode: 0
            })
        }catch(err){
            if(err.response){
                addNotification(err.response.data.message, 'bg-warning')
            }else{
                addNotification('Error al registrar movimiento', 'bg-warning')
            }            
        }
        
        // console.log(data)
    }

    handleSubmitAniadir(movimiento){
        this.mostrarDetalleMovimiento(movimiento)
    }

    handleSubmitAniadirItem(combinacion){
        const idx = this.state.movimiento.items.findIndex(elm => elm.id_combinacion === combinacion.id_combinacion)

        let items = []
        
        if(this.validarAniadirItem(combinacion)){
            if(idx === -1){
                items = [...this.state.movimiento.items, combinacion] 
            }else{
                items = this.state.movimiento.items
                items[idx] = {
                    ...items[idx],
                    cantidad: items[idx].cantidad + combinacion.cantidad
                }
            }

            this.setState({ 
                movimiento: {
                    ...this.state.movimiento,
                    items
                }                
            })
        }        
    }

    handleDoubleClickMovimientosAlmacenRow(evt){
        const id_movimiento = evt.currentTarget.getAttribute('data-id')
        const movimiento = {
            id_movimiento,
            tipo: 1,
            id_motivo: undefined,                
            id_orden_compra: undefined,
            guia_remision: undefined
        }
        this.mostrarDetalleMovimiento(movimiento)
    }

    mostrarDetalleMovimiento(movimiento){
        this.setState({
            mode: 2,
            movimiento: {
                ...movimiento,
                items: []
            }
        }, () => {
            if(this.state.movimiento.id_orden_compra){
                this.obtenerOrdenesCompra()
            }
            // this.obtenerOrdenesCompra()
        })
    }

    validarAniadirItem(combinacion){
        let result = true        

        if(this.state.movimiento.id_motivo === 1){
            if(this.state.movimiento.orden_compra.find(elm => elm.id_combinacion === combinacion.id_combinacion)){
                const idx = this.state.movimiento.items.findIndex(elm => elm.id_combinacion === combinacion.id_combinacion)
                const cantidad = idx !== -1 ? this.state.movimiento.items[idx].cantidad : 0

                this.state.movimiento.orden_compra.forEach(elm => {
                    if(
                        combinacion.id_combinacion === elm.id_combinacion &&
                        (combinacion.cantidad + cantidad) > elm.cantidad 
                    ){
                        result = false
                        addNotification('No puede agregar una cantidad mayor a la de la orden de compra.', 'bg-warning')
                    }
                })
            }else{
                result = false
                addNotification('No se pueden agregar items que no estan en la orden de compra.', 'bg-warning')
            }            
        }
        
        return result
    }

    async obtenerOrdenesCompra(){
        const data = await OrdenCompra.get(this.state.movimiento.id_orden_compra)
        this.setState({
            movimiento: {
                ...this.state.movimiento,
                orden_compra: data.results
            }
        })
    }
}