import React from "react";
import SelectSearchContainer from "../SelectSearch/SelectSearchContainer";

export default class CombinacionNuevoView extends React.Component {
  render() {
    return (
      <div className="ps-4 pe-4 pt-3">
        <div className="form-group mb-2 position-relative">
          <label>Descripción</label>
          <input
            type="text"
            className="form-control"
            value={this.props.combinacion.descripcion}
            onChange={this.props.onChangeDescripcion}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Stock</label>
          <input
            type="number"
            className="form-control"
            value={this.props.combinacion.stock}
            onChange={this.props.onChangeStock}
            disabled
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Stock Mínimo</label>
          <input
            type="number"
            className="form-control"
            value={this.props.combinacion.stock_min}
            onChange={this.props.onChangeStockMin}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Stock Auto</label>
          <input
            type="number"
            className="form-control"
            value={this.props.combinacion.stock_auto}
            onChange={this.props.onChangeStockAuto}
          />
        </div>
        <div
          className="separator"
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "rgba(0,0,0,0.1)",
            margin: "20px 0",
            borderRadius: "2px",
          }}
        ></div>
        <div className="form-group mb-2 position-relative">
          <label>Atributo</label>
          <SelectSearchContainer
            options={this.props.atributos}
            id="item_atributo"
            valueKey="id_item_atributo"
            displayKey="atributo"
            onChange={this.props.onChangeAtributo}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Valor</label>
          <SelectSearchContainer
            options={this.props.valores}
            id="item_atributo_valor"
            valueKey="id_item_atributo_valor"
            displayKey="item_atributo_valor"
            onChange={this.props.onChangeAtributoValor}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <button
            className="btn btn-primary rounded-pill"
            onClick={this.props.onClickAniadirPropiedad}
          >
            Añadir
          </button>
        </div>
        <div
          className="separator"
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "rgba(0,0,0,0.1)",
            margin: "20px 0",
            borderRadius: "2px",
          }}
        ></div>
        <ul className="list-unstyled">{this.getAtributos()}</ul>
        <div className="aside-footer position-fixed end-0 bottom-0 p-3">
          <button
            className="btn btn-primary rounded-pill"
            onClick={this.props.onSubmit}
          >
            Guardar
          </button>
        </div>
      </div>
    );
  }

  getAtributos() {
    return this.props.combinacion.atributos.map((elm) => (
      <li key={"comb-atributo-valor" + elm.id_item_atributo_valor}>
        {elm.item_atributo} : {elm.item_atributo_valor}
      </li>
    ));
  }
}
