import * as Axios from "../libs/Axios/Axios";

const path = "/producto";

export default class Producto {
  static list() {
    return Axios.get(path);
  }

  static getOne(idPersona) {
    return Axios.get(path + "/" + idPersona);
  }

  static getCombinacion(idCombinacion) {
    return Axios.get(path + "/combinacion/" + idCombinacion);
  }

  static addCombinacion(combinacion) {
    return Axios.post(path + "/combinacion", combinacion);
  }

  static getAtributos() {
    return Axios.get(path + "/atributo");
  }

  static getOneAtributo(idAtributo) {
    return Axios.get(path + "/atributo/" + idAtributo);
  }

  static add(descripcion) {
    const params = { descripcion };
    return Axios.post(path, params);
  }

  static conjuntoList() {
    return Axios.get(path + "/conjunto");
  }
}
