import React from "react";
import Cuenta from "../../services/Cuenta";
import CuentaList from "./CuentaList";

export default class CuentaListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: "",
      cuentas: [],
      cuentasVisibles: [],
    };

    this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this);
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
  }

  render() {
    return (
      <CuentaList
        cuentas={this.state.cuentasVisibles}
        searchWord={this.state.searchWord}
        onChangeSearchWord={this.handleChangeSearchWord}
        onClickNuevo={this.props.onClickNuevo}
        onDoubleClickRow={this.handleDoubleClickRow}
      />
    );
  }

  componentDidMount() {
    this.listarCuentas();
  }

  handleChangeSearchWord(evt) {
    this.setState(
      {
        searchWord: evt.target.value,
      },
      () => this.buscarCuentas()
    );
  }

  handleDoubleClickRow(evt) {
    const id_cuenta = evt.currentTarget.getAttribute("data-id");
    this.props.onSelectCuenta(id_cuenta);
  }

  async listarCuentas() {
    const { results: cuentas } = await Cuenta.list();
    this.setState(
      {
        cuentas,
      },
      () => this.buscarCuentas()
    );
  }

  buscarCuentas() {
    let cuentasVisibles = [];

    if (this.state.searchWord.trim() === "") {
      cuentasVisibles = this.state.cuentas;
    } else {
      const word = this.state.searchWord.trim().toUpperCase();
      cuentasVisibles = this.state.cuentas.filter(
        (cuenta) =>
          String(cuenta.id_cuenta) === word ||
          cuenta.descripcion.toUpperCase().includes(word)
      );
    }

    this.setState({
      cuentasVisibles,
    });
  }
}
