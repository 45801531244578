import React from "react";
import Empresa from "../../services/Empresa";
import EmpresaList from "./EmpresaList";

export default class EmpresaListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: "",
      empresas: [],
      empresasVisibles: [],
    };

    this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this);
  }

  render() {
    return (
      <EmpresaList
        empresas={this.state.empresasVisibles}
        searchWord={this.state.searchWord}
        onChangeSearchWord={this.handleChangeSearchWord}
        onClickNuevo={this.props.onClickNuevo}
      />
    );
  }

  componentDidMount() {
    this.listarEmpresas();
  }

  handleChangeSearchWord(evt) {
    this.setState(
      {
        searchWord: evt.target.value,
      },
      () => this.buscarEmpresas()
    );
  }

  async listarEmpresas() {
    const data = await Empresa.list();
    const empresas = data.results;
    this.setState(
      {
        empresas,
      },
      () => this.buscarEmpresas()
    );
  }

  buscarEmpresas() {
    let empresasVisibles = [];

    if (this.state.searchWord.trim() === "") {
      empresasVisibles = this.state.empresas;
    } else {
      const word = this.state.searchWord.trim().toUpperCase();
      empresasVisibles = this.state.empresas.filter(
        (empresa) =>
          String(empresa.id_persona) === word ||
          empresa.razon_social.toUpperCase().includes(word)
      );
    }

    this.setState({
      empresasVisibles,
    });
  }
}
