import React from 'react'
import OrdenesCompraList from './OrdenesCompraList'
import OrdenCompra from '../../services/OrdenCompra'
import { addNotification } from '../Notifications/Notifications'

export default class OrdenesCompraListContainer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            items: [],
            display_items: [],
            search_word: ''
        }
        this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this)
    }

    render() {
        return <OrdenesCompraList 
                    items={this.state.display_items}
                    onClickNuevo={this.props.onClickNuevo} 
                    searchWord={this.state.search_word}
                    onChangeSearchWord={this.handleChangeSearchWord}
                    onDoubleClick={this.props.onDoubleClickListItem} />
    }

    componentDidMount() {
        this.obtenerOrdenesCompra()
    }

    handleChangeSearchWord(evt){
        this.setState({
            search_word: evt.target.value
        }, () => {
            this.buscar()
        })
    }

    async obtenerOrdenesCompra(){
        try{
            const data = await OrdenCompra.list()
            if(data.complete){
                this.setState({items: data.results}, () => {
                    this.buscar()
                })
            }else{
                addNotification(data.err, 'bg-warning')    
            }
        }catch(err){
            addNotification('Error al obtener ordenes de compra', 'bg-danger')
        }
    }

    buscar(){
        const display_items = this.state.items.filter(elm => {
            const search_word = this.state.search_word.toUpperCase().trim()
            const razon_social = elm.razon_social.toUpperCase().trim()
            const ruc = elm.ruc.toUpperCase().trim()
            const creado_el = elm.creado_el.toUpperCase().trim()

            return (
                razon_social.includes(search_word) ||
                ruc.includes(search_word) ||
                creado_el.includes(search_word)
            )
        })

        this.setState({ display_items })
    }
}