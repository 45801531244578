import React from "react";
import ContizacionDetalle from "./CotizacionDetalle";
import Cotizacion from "../../services/Cotizacion";
import Empresa from "../../services/Empresa";

export default class CotizacionDetalleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Nueva Cotización",
      items: [],
      tipoAside: 2,
      tiposAside: [
        { id: 1, value: "Equipamiento" },
        { id: 2, value: "Servicio" },
      ],
      subtotal: 0,
      empresa: undefined,
    };
    this.handleChangeTipoAside = this.handleChangeTipoAside.bind(this);
    this.handleSubmitServicioAniadir =
      this.handleSubmitServicioAniadir.bind(this);
    this.handleSubmitCombinacionAniadir =
      this.handleSubmitCombinacionAniadir.bind(this);
    this.handleSubmitCotizacion = this.handleSubmitCotizacion.bind(this);
    this.handleClickDeleteRow = this.handleClickDeleteRow.bind(this);
    if (this.props.id_empresa) {
      this.mostrarEmpresa();
    } else if (this.props.id_cotizacion) {
      this.mostrarCotizacion();
    }
  }

  render() {
    return (
      <ContizacionDetalle
        empresa={this.state.empresa}
        items={this.state.items}
        tipoAside={this.state.tipoAside}
        tiposAside={this.state.tiposAside}
        subtotal={this.state.subtotal}
        title={this.state.title}
        onChangeTipoAside={this.handleChangeTipoAside}
        onSubmitServicioAniadir={this.handleSubmitServicioAniadir}
        onSubmitCombinacionAniadir={this.handleSubmitCombinacionAniadir}
        onSubmitCotizacion={this.handleSubmitCotizacion}
        onClickDeleteRow={this.handleClickDeleteRow}
        id_cotizacion={this.props.id_cotizacion}
      />
    );
  }

  handleChangeTipoAside(tipoAside) {
    this.setState({ tipoAside });
  }

  handleSubmitServicioAniadir(item) {
    this.setState(
      {
        items: [
          ...this.state.items,
          { ...item, importe: item.cantidad * item.precio },
        ],
      },
      () => {
        this.calcularSubtotal();
      }
    );
  }

  handleSubmitCombinacionAniadir(item) {
    this.setState(
      {
        items: [
          ...this.state.items,
          { ...item, importe: item.cantidad * item.precio },
        ],
      },
      () => {
        const subtotal = this.state.items.reduce((A, B) => A + B.importe, 0);
        this.setState({ subtotal });
      }
    );
  }

  handleClickDeleteRow(idx) {
    const items = this.state.items;
    items.splice(idx, 1);
    this.setState({ items });
  }

  async handleSubmitCotizacion() {
    const data = {
      cotizacion: this.state.items,
      id_empresa: this.props.id_empresa,
      id_contacto: this.props.id_contacto,
    };
    let response;

    if (this.props.id_cotizacion) {
      response = await Cotizacion.update(this.props.id_cotizacion, data);
    } else {
      response = await Cotizacion.add(data);
    }

    if (response.complete === true) {
      this.props.onSubmit();
    }
  }

  calcularSubtotal() {
    const subtotal = this.state.items.reduce((A, B) => A + B.importe, 0);
    this.setState({ subtotal });
  }

  async mostrarEmpresa() {
    const response = await Empresa.get(this.props.id_empresa);
    this.setState({
      empresa: response,
    });
  }

  async mostrarCotizacion() {
    const { results } = await Cotizacion.get(this.props.id_cotizacion);
    this.setState(
      {
        title: `Cotizacion #${results[0].id_cotizacion}`,
        items: results,
      },
      () => {
        this.calcularSubtotal();
      }
    );
  }
}
