import React from "react";
import ServicioAniadir from "./ServicioAniadir";
import Ubicacion from "../../services/Ubicacion";
import Servicio from "../../services/Servicio";

export default class ServicioAniadirContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nuevo: {
        id_departamento_ubicacion: undefined,
        id_provincia_ubicacion: undefined,
        id_distrito_ubicacion: undefined,
        id_tipo_via_ubicacion: undefined,
        via_ubicacion: undefined,
        numeracion_via_ubicacion: undefined,
        descripcion_ubicacion: undefined,
        id_producto: undefined,
        cantidad: undefined,
        precio: 0,
        armado: false,
      },
      departamentos: [],
      provincias: [],
      distritos: [],
      tipos_via: [],
      productos: [],
    };
    this.handleChangeDepartamento = this.handleChangeDepartamento.bind(this);
    this.handleChangeProvincia = this.handleChangeProvincia.bind(this);
    this.handleChangeDistrito = this.handleChangeDistrito.bind(this);
    this.handleChangeTipoVia = this.handleChangeTipoVia.bind(this);
    this.handleChangeVia = this.handleChangeVia.bind(this);
    this.handleChangeNumeroVia = this.handleChangeNumeroVia.bind(this);
    this.handleChangeDescripcion = this.handleChangeDescripcion.bind(this);
    this.handleChangeProducto = this.handleChangeProducto.bind(this);
    this.handleChangeCantidad = this.handleChangeCantidad.bind(this);
    this.handleChangePrecio = this.handleChangePrecio.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeArmado = this.handleChangeArmado.bind(this);
    this.mostrarDepartamentos();
    this.mostrarTiposVia();
    this.mostrarProductos();
  }

  render() {
    return (
      <ServicioAniadir
        nuevo={this.state.nuevo}
        departamentos={this.state.departamentos}
        provincias={this.state.provincias}
        distritos={this.state.distritos}
        tipos_via={this.state.tipos_via}
        productos={this.state.productos}
        onChangeDepartamento={this.handleChangeDepartamento}
        onChangeProvincia={this.handleChangeProvincia}
        onChangeDistrito={this.handleChangeDistrito}
        onChangeTipoVia={this.handleChangeTipoVia}
        onChangeVia={this.handleChangeVia}
        onChangeNumeroVia={this.handleChangeNumeroVia}
        onChangeDescripcion={this.handleChangeDescripcion}
        onChangeProducto={this.handleChangeProducto}
        onChangeCantidad={this.handleChangeCantidad}
        onChangePrecio={this.handleChangePrecio}
        onChangeArmado={this.handleChangeArmado}
        onSubmit={this.handleSubmit}
      />
    );
  }

  handleChangeDepartamento(id_departamento_ubicacion) {
    this.setState(
      { nuevo: { ...this.state.nuevo, id_departamento_ubicacion } },
      () => {
        this.mostrarProvincias();
      }
    );
  }

  handleChangeProvincia(id_provincia_ubicacion) {
    this.setState(
      { nuevo: { ...this.state.nuevo, id_provincia_ubicacion } },
      () => {
        this.mostrarDistritos();
      }
    );
  }

  handleChangeDistrito(id_distrito_ubicacion) {
    this.setState({ nuevo: { ...this.state.nuevo, id_distrito_ubicacion } });
  }

  handleChangeTipoVia(id_tipo_via_ubicacion) {
    this.setState({ nuevo: { ...this.state.nuevo, id_tipo_via_ubicacion } });
  }

  handleChangeVia(evt) {
    this.setState({
      nuevo: { ...this.state.nuevo, via_ubicacion: evt.target.value },
    });
  }

  handleChangeNumeroVia(evt) {
    this.setState({
      nuevo: {
        ...this.state.nuevo,
        numeracion_via_ubicacion: evt.target.value,
      },
    });
  }

  handleChangeDescripcion(evt) {
    this.setState({
      nuevo: {
        ...this.state.nuevo,
        descripcion_ubicacion: evt.target.value,
      },
    });
  }

  handleChangeProducto(id_producto) {
    this.setState({ nuevo: { ...this.state.nuevo, id_producto } });
  }

  handleChangeCantidad(evt) {
    this.setState({
      nuevo: { ...this.state.nuevo, cantidad: evt.target.value },
    });
  }

  handleChangePrecio(evt) {
    this.setState({
      nuevo: { ...this.state.nuevo, precio: evt.target.value },
    });
  }

  handleChangeArmado(evt) {
    this.setState({
      nuevo: { ...this.state.nuevo, armado: evt.target.checked },
    });
  }

  handleSubmit() {
    const item = {
      ...this.state.productos.find(
        (elm) => this.state.nuevo.id_producto === elm.id_item
      ),
      ...this.state.nuevo,
    };
    this.props.onSubmit(item);
  }

  async mostrarDepartamentos() {
    const { results: departamentos } = await Ubicacion.departamentosList();
    this.setState({ departamentos });
  }

  async mostrarProvincias() {
    const { results: provincias } = await Ubicacion.provinciasList(
      this.state.nuevo.id_departamento_ubicacion
    );
    this.setState({ provincias });
  }

  async mostrarDistritos() {
    const { results: distritos } = await Ubicacion.distritosList(
      this.state.nuevo.id_provincia_ubicacion
    );
    this.setState({ distritos });
  }

  async mostrarTiposVia() {
    const { results: tipos_via } = await Ubicacion.tiposViaList();
    this.setState({ tipos_via });
  }

  async mostrarProductos() {
    const { results: productos } = await Servicio.list();
    this.setState({ productos });
  }
}
