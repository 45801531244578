import React from "react";
import Cotizacion from "../../services/Cotizacion";
import CotizacionList from "./CotizacionList";

export default class CotizacionListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: "",
      cotizaciones: [],
      cotizacionesVisibles: [],
    };

    this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.time !== this.props.time) {
      this.listarCotizaciones();
    }
  }

  render() {
    return (
      <CotizacionList
        cotizaciones={this.state.cotizacionesVisibles}
        searchWord={this.state.searchWord}
        onChangeSearchWord={this.handleChangeSearchWord}
        onClickNuevo={this.props.onClickNuevo}
        onDoubleClickCotizacionListItem={
          this.props.onDoubleClickCotizacionListItem
        }
        onClickMostrarCotizacion={this.props.onClickMostrarCotizacion}
      />
    );
  }

  componentDidMount() {
    this.listarCotizaciones();
  }

  handleChangeSearchWord(evt) {
    this.setState(
      {
        searchWord: evt.target.value,
      },
      () => this.buscarCotizaciones()
    );
  }

  async listarCotizaciones() {
    const { results: cotizaciones } = await Cotizacion.list();
    this.setState(
      {
        cotizaciones: cotizaciones || [],
      },
      () => this.buscarCotizaciones()
    );
  }

  buscarCotizaciones() {
    let cotizacionesVisibles = [];

    if (this.state.searchWord.trim() === "") {
      cotizacionesVisibles = this.state.cotizaciones;
    } else {
      const word = this.state.searchWord.trim().toUpperCase();
      cotizacionesVisibles = this.state.cotizaciones.filter(
        (cotizacion) =>
          String(cotizacion.id_cotizacion) === word ||
          cotizacion.razon_social.toUpperCase().includes(word)
      );
    }

    this.setState({
      cotizacionesVisibles,
    });
  }
}
