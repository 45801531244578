import React from "react";
import "./App.scss";

import NavbarContainer from "./components/Navbar/NavbarContainer";
import TopbarContainer from "./components/Topbar/TopbarContainer";
import ModuleContainer from "./modules/ModuleContainer";

let ref = undefined;

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChangeModule = this.handleChangeModule.bind(this);
    ref = this;
  }

  render() {
    return (
      <div>
        <NavbarContainer onChangeModule={this.handleChangeModule} />
        <ModuleContainer module={this.state.module} />
        <TopbarContainer />
      </div>
    );
  }

  handleChangeModule(module) {
    this.setState({ module });
  }
}

const changeModule = (module) => {
  ref.handleChangeModule(module);
};

export { changeModule };
