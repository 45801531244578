import React from 'react'
import SelectSearchContainer from '../SelectSearch/SelectSearchContainer'
import FileChooserContainer from '../FileChooser/FileChooserContainer'

export default class MovimientoAlmacenAniadir extends React.Component {
    render() {
        const ipt_oc = Number(this.props.idMotivo) === 1 ? (
            <div className="form-group mb-2 position-relative">
                <label>Orden de Compra</label>
                <SelectSearchContainer options={this.props.ordenesCompra} id='orden_compra' valueKey='id_orden_compra' displayKey={'descripcion'} onChange={this.props.onChangeOrdenCompra}/>
            </div>
        ) : ''

        const ipt_guia = Number(this.props.tipo) === 1 ? (
            <div className="form-group mb-2 position-relative">
                <label>Guía</label>
                <FileChooserContainer mode='base64' onChange={this.props.onChangeFileGuia} />
            </div>
        ) : ''

        const ipt_trabajador = this.props.trabajadores.length > 0 ? (
            <div className="form-group mb-2 position-relative">
                <label>Trabajador</label>
                <SelectSearchContainer options={this.props.trabajadores} id='trabajador' valueKey='id_trabajador' displayKey={'trabajador'} onChange={this.props.onChangeTrabajador}/>
            </div>
        ) : ''

        return (
            <div className="ps-4 pe-4 pt-3">
                <div className="form-group mb-2 position-relative">
                    <label>Tipo</label>
                    <SelectSearchContainer value={this.props.tipo} options={this.props.tipos} id='producto' valueKey='id' displayKey='descripcion' onChange={this.props.onChangeTipo}/>
                </div>
                <div className="form-group mb-2 position-relative">
                    <label>Motivo</label>
                    <SelectSearchContainer options={this.props.motivos} id='motivo' valueKey='id_movimiento_almacen_motivo' displayKey='descripcion' onChange={this.props.onChangeMotivo}/>
                </div>
                { ipt_oc }
                { ipt_guia }
                { ipt_trabajador }
                <div className="aside-footer position-fixed end-0 bottom-0 p-3">
                    <button className="btn btn-primary rounded-pill" onClick={this.props.onSubmit}>Procesar</button>
                </div>
            </div>
        )
    }

}