import React from 'react'
import MovimientosAlmacenDetalle from './MovimientosAlmacenDetalle'
import MovimientoAlmacen from '../../services/MovimientoAlmacen'

export default class MovimientosAlmacenDetalleContainer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            movimiento: this.props.movimiento
        }
        this.obtenerMovimiento()
    }

    render(){
        return <MovimientosAlmacenDetalle 
                    movimiento={this.state.movimiento}
                    onSubmit={this.props.onSubmit} />
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.movimiento !== this.props.movimiento){
            this.setState({
                movimiento: {
                    ...this.props.movimiento
                }
            })
        }
    }

    async obtenerMovimiento(){
        if(this.state.movimiento.id_movimiento){
            const { results: items } = await MovimientoAlmacen.get(this.state.movimiento.id_movimiento)
            this.setState({
                movimiento: {
                    ...this.state.movimiento,
                    items
                }
            })
        }
    }

}