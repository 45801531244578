import React from "react";
import EmpresaContacto from "./EmpresaContacto";
import Persona from "../../services/Persona";

export default class EmpresaContactoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modo: "lista",
      contactos: [],
      id_contacto_select: -1,
      id_contacto_to_edit: undefined,
    };
    this.handleClickNuevoContacto = this.handleClickNuevoContacto.bind(this);
    this.handleSubmitContactoNuevo = this.handleSubmitContactoNuevo.bind(this);
    this.handleClickAtras = this.handleClickAtras.bind(this);
    this.handleClickContacto = this.handleClickContacto.bind(this);
    this.handleClickOptions = this.handleClickOptions.bind(this);
  }

  render() {
    return (
      <EmpresaContacto
        modo={this.state.modo}
        contactos={this.state.contactos}
        idEmpresa={this.props.idEmpresa}
        id_contacto_select={this.state.id_contacto_select}
        id_contacto_to_edit={this.state.id_contacto_to_edit}
        onClickNuevoContacto={this.handleClickNuevoContacto}
        onClickAtras={this.handleClickAtras}
        onClickSiguiente={this.props.onClickSiguiente}
        onSubmitContactoNuevo={this.handleSubmitContactoNuevo}
        onClickContacto={this.handleClickContacto}
        onClickOptions={this.handleClickOptions}
      />
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.idEmpresa !== this.props.idEmpresa) {
      this.getContactos();
    }
  }

  handleClickNuevoContacto() {
    this.setState({ modo: "nuevo" });
  }

  handleClickAtras() {
    this.setState({ modo: "lista" });
  }

  handleSubmitContactoNuevo() {
    this.setState({ modo: "lista" }, () => {
      this.getContactos();
    });
  }

  async getContactos() {
    const { results: contactos } = await Persona.getContactos(
      this.props.idEmpresa
    );
    this.setState({
      contactos,
    });
  }

  handleClickContacto(evt) {
    const id_contacto_select = Number(
      evt.currentTarget.getAttribute("data-id")
    );
    this.setState({ id_contacto_select }, () => {
      this.props.onChangeIdContacto(this.state.id_contacto_select);
    });
  }

  handleClickOptions(evt) {
    const idContacto = evt.currentTarget.getAttribute("data-id");
    this.setState({ modo: "nuevo", id_contacto_to_edit: idContacto });
  }
}
