import React from 'react'
import SelectSearchContainer from '../SelectSearch/SelectSearchContainer'
import {ReactComponent as CerrarIcon} from '../../images/Cerrar.svg'

export default class OrdenesCompraDetalle extends React.Component{
    render() {
        return (
            <main>
                <div className="header-block">
                    <div className="d-flex justify-content-between align-items-baseline mb-3">
                        <div className="title d-flex align-items-baseline justify-content-between w-100">
                            <h3>
                                {this.props.ordenCompra ?                                      
                                    `Orden de Compra #${this.props.ordenCompra.id_orden_compra}` :
                                    'Nueva Orden de Compra'
                                }
                            </h3>
                            <div className="form-group mb-2 position-relative">
                                <SelectSearchContainer 
                                    options={this.props.proveedores} 
                                    id='proveedor' 
                                    valueKey='id_persona' 
                                    displayKey='razon_social' 
                                    onChange={this.props.onChangeProveedor}
                                    value={this.props.ordenCompra ? this.props.ordenCompra.id_proveedor : undefined}
                                    placeholder="Proveedor" />
                            </div>  
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline">                      
                        <div>
                            <small className='small'>{this.props.items.length} items agregados</small>
                        </div>                                     
                    </div>
                </div>
                <div className="body-block">
                    {this.getTable()}
                </div>
                <div className="footer-block position-absolute bottom-0 start-0 mb-4 w-100">
                    {this.getFooter()}
                </div>             
            </main>
        )
    }

    getFooter(){
        const subtotal = this.props.subtotal
        const igv = this.props.subtotal * 0.18
        const total = subtotal + igv

        return (
            <div className="bg-white px-3 py-2 d-flex justify-content-between align-items-end">
                <div className="d-flex">
                    <div className="form-group me-2">
                        <label>SUB TOTAL</label>
                        <input type="text" className="form-control" placeholder="" disabled value={"S/ " + subtotal.toFixed(2)} />
                    </div>
                    <div className="form-group me-2">
                        <label>I.G.V.</label>
                        <input type="text" className="form-control" placeholder="" disabled value={"S/ " + igv.toFixed(2)} />
                    </div>
                    <div className="form-group me-2">
                        <label>TOTAL</label>
                        <input type="text" className="form-control" placeholder="" disabled value={"S/ " + total.toFixed(2)} />
                    </div>
                </div>
                <div>
                    <button className="btn btn-primary rounded-pill text-nowrap" onClick={this.props.onSubmit} disabled={this.props.ordenCompra ? true : false} >GENERAR OC</button>
                </div>
            </div>
        )
    }

    getTable(){
        if(this.props.items.length > 0){
            return (
                <div className='table-component'>
                    <table className="w-100">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getRows()}
                        </tbody>
                    </table>
                </div>                
            )
        }else{
            return ''   
        }
    }

    getRows(){
        return this.props.items.map((item) =>                 
            <tr className="position-relative" key={'itm-'+item.id_combinacion} data-id={item.id_combinacion} onClick={this.props.onClickRow}>
                <td>{item.combinacion}</td>
                <td>{item.cantidad}</td>
                <td>S/ {item.precio.toFixed(2)}</td>
                <td>S/ {(item.precio * item.cantidad).toFixed(2)}</td>
                <td className="p-0 position-relative options">
                    <CerrarIcon className={'position-absolute left-0 top-0' + (this.props.ordenCompra ? ' d-none' : '')} type="button" data-id={item.id_combinacion} onClick={this.props.onClickDeleteRow} />
                </td>                    
            </tr>
        )
    }
}