import * as Axios from "../libs/Axios/Axios";

const url = `/auth`;

export default class Auth {
  static login(usuario) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`${url}/login`, usuario);
        localStorage.setItem("id_usuario", response.id_usuario);
        localStorage.setItem("nombre", response.nombre);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}
