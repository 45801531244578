import { ReactComponent as CarritoIcon } from "../images/Carrito.svg";
import { ReactComponent as CargaIcon } from "../images/Carga.svg";
import { ReactComponent as ApoyoIcon } from "../images/Apoyo.svg";
import { ReactComponent as PortapapelesIcon } from "../images/Portapapeles.svg";

import OrdenesCompraContainer from "../modules/OrdenesCompra/OrdenesCompraContainer";
import MovimientosAlmacenContainer from "../modules/MovimientosAlmacen/MovimientosAlmacenContainer";
import StockContainer from "../modules/Stock/StockContainer";

/**
 * CRM MODULES
 */

import CuentasContainer from "../modules/Cuentas/CuentasContainer";
import CotizacionesContainer from "../modules/Cotizaciones/CotizacionesContainer";
import EmpresasContainer from "../modules/Empresas/EmpresasContainer";
import Items from "../modules/Items/Items";

const Modules = {
  1: {
    title: "CRM",
    icon: <CarritoIcon />,
    modules: {
      1: {
        title: "Cuentas",
        icon: <PortapapelesIcon />,
        component: <CuentasContainer />,
      },
      2: {
        title: "Cotizaciones",
        icon: <PortapapelesIcon />,
        component: <CotizacionesContainer />,
      },
      3: {
        title: "Empresas",
        icon: <PortapapelesIcon />,
        component: <EmpresasContainer />,
      },
    },
  },
  2: {
    title: "Logistica",
    icon: <CargaIcon />,
    modules: {
      1: {
        title: "Ordenes de Compra",
        icon: <PortapapelesIcon />,
        component: <OrdenesCompraContainer />,
      },
      2: {
        title: "Movimientos",
        icon: <PortapapelesIcon />,
        component: <MovimientosAlmacenContainer />,
      },
      3: {
        title: "Items",
        icon: <PortapapelesIcon />,
        component: <Items />,
      },
      4: {
        title: "Stock",
        icon: <PortapapelesIcon />,
        component: <StockContainer />,
      },
    },
  },
  3: {
    title: "GTH",
    icon: <ApoyoIcon />,
  },
};

export { Modules };
