import React from "react";
import ModalContainer from "../Modal/ModalContainer";

export default class CotizacionCheck extends React.Component {
  render() {
    const include_content = this.getIncludeContent();
    const exclude_content = this.getExcludeContent();
    return (
      <ModalContainer
        size="lg"
        onSubmitModal={this.props.onSubmitModal}
        title="Generar Cotización"
      >
        <h6 className="mb-2">La propuesta incluye:</h6>
        {include_content}
        <h6 className="mt-3 mb-2">La propuesta excluye:</h6>
        {exclude_content}
      </ModalContainer>
    );
  }

  getIncludeContent() {
    return this.props.opciones.map((elm) => {
      if (elm.tipo === 1) {
        const activo = this.props.opciones_select.find(
          (elm_select) => elm.id === elm_select.id
        )
          ? true
          : false;

        return (
          <div className="input-group" key={`cot-opt-${elm.id}`}>
            <div className="input-group-text">
              <input
                className="form-check-input mt-0"
                type="checkbox"
                checked={activo}
                data-id={elm.id}
                aria-label="Checkbox for following text input"
                onChange={this.props.onCheckBoxChange}
              />
            </div>
            <input
              type="text"
              value={elm.titulo}
              className="form-control bg-white"
              aria-label="Text input with checkbox"
              disabled
            />
          </div>
        );
      } else {
        return "";
      }
    });
  }

  getExcludeContent() {
    return this.props.opciones.map((elm) => {
      if (elm.tipo === 2) {
        const activo = this.props.opciones_select.find(
          (elm_select) => elm.id === elm_select.id
        )
          ? true
          : false;

        return (
          <div className="input-group" key={`cot-opt-${elm.id}`}>
            <div className="input-group-text">
              <input
                className="form-check-input mt-0"
                type="checkbox"
                checked={activo}
                aria-label="Checkbox for following text input"
                data-id={elm.id}
                onChange={this.props.onCheckBoxChange}
              />
            </div>
            <input
              type="text"
              value={elm.titulo}
              className="form-control bg-white"
              aria-label="Text input with checkbox"
              disabled
            />
          </div>
        );
      } else {
        return "";
      }
    });
  }
}
