import React from 'react'
import AsideContainer from '../../components/Aside/AsideContainer'
import MovimientoAlmacenAniadirContainer from '../../components/MovimientoAlmacenAniadir/MovimientoAlmacenAniadirContainer'
import MovimientosAlmacenListContainer from '../../components/MovimientosAlmacenList/MovimientosAlmacenListContainer'
import CombinacionAniadirContainer from '../../components/CombinacionAniadir/CombinacionAniadirContainer'
import MovimientosAlmacenDetalleContainer from '../../components/MovimientosAlmacenDetalle/MovimientosAlmacenDetalleContainer'

export default class MovimientosAlmacen extends React.Component{
    render() {
        const content = this.getContent()
        return content
    }

    getContent(){
        let content = ''
        switch (this.props.mode){
            case 1: 
                content = (
                    <div>
                        <AsideContainer title="Añadir Movimiento" >
                            <MovimientoAlmacenAniadirContainer onSubmit={this.props.onSubmitAniadir}/>
                        </AsideContainer>
                        <MovimientosAlmacenListContainer 
                            onClickNuevo={this.props.onClickNuevo}
                            onDoubleClickRow={this.props.onDoubleClickMovimientosAlmacenRow}/>
                    </div>
                )
                break
            case 2:
                const aside = !this.props.movimiento.id_movimiento ? (
                    <AsideContainer title="Añadir Items" >
                        <CombinacionAniadirContainer onSubmit={this.props.onSubmitAniadirItem} hiddenPrice={true}/>
                    </AsideContainer>
                ) : ''
                content = (
                    <div>
                        {aside}
                        <MovimientosAlmacenDetalleContainer 
                            movimiento={this.props.movimiento} 
                            items={this.props.movimiento.items} 
                            onSubmit={this.props.onSubmitDetalle}/>
                    </div>
                )
                break
            default:
                content = (
                    <div>
                        <MovimientosAlmacenListContainer 
                            onClickNuevo={this.props.onClickNuevo}
                            onDoubleClickRow={this.props.onDoubleClickMovimientosAlmacenRow}/>
                    </div>
                )
                break

        }
        return content
    }
}