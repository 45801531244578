import React from "react";
import AsideContainer from "../../components/Aside/AsideContainer";

export default class Cuentas extends React.Component {
  render() {
    const content = this.getContent();
    return content;
  }

  getContent() {
    const aside = this.props.aside ? (
      <AsideContainer title="Añadir Movimiento">
        {this.props.aside}
      </AsideContainer>
    ) : (
      ""
    );

    return (
      <div>
        {aside}
        {this.props.component}
      </div>
    );
  }
}
