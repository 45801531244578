import React from "react";
import ContactoNuevoContainer from "../ContactoNuevo/ContactoNuevoContainer";
import { ReactComponent as OptionsImg } from "../../images/Options.svg";

export default class EmpresaContacto extends React.Component {
  render() {
    return this.getContent();
  }

  getContent() {
    if (this.props.modo === "lista") {
      return (
        <div>
          <div className="content-frame">
            <div className="form-group pt-3">
              {this.getContactos()}
              <div
                className="card card-add bg-light rounded-0 position-relative enable-button-pointers"
                role="button"
                onClick={this.props.onClickNuevoContacto}
              >
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="aside-footer position-fixed end-0 bottom-0 p-3">
            <button
              className="btn btn-primary rounded-pill"
              onClick={this.props.onClickSiguiente}
            >
              Siguiente
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <ContactoNuevoContainer
          idEmpresa={this.props.idEmpresa}
          onClickAtras={this.props.onClickAtras}
          onSubmit={this.props.onSubmitContactoNuevo}
          id_contacto_to_edit={this.props.id_contacto_to_edit}
        />
      );
    }
  }

  getContactos() {
    return this.props.contactos.map((elm, idx) => (
      <div
        type="button"
        className={
          "card p-2 rounded-0 mb-2" +
          (this.props.id_contacto_select === elm.id_persona_contacto
            ? " active"
            : "")
        }
        key={"contact-list-item-" + elm.id_persona_contacto}
        onClick={this.props.onClickContacto}
        data-id={elm.id_persona_contacto}
      >
        <div className="d-flex justify-content-between align-items-start mb-2">
          <h6
            className="text-nowrap mt-1 mb-0"
            title="Manuel Alejandro Carbajal Cáceres"
          >
            {elm.nombre}
          </h6>
          <button
            data-id={elm.id_persona_contacto}
            className="btn btn-sm p-0 lh-1"
            onClick={this.props.onClickOptions}
          >
            <OptionsImg />
          </button>
        </div>
        <div>
          <p className="datos rounded-0 mb-0">
            Relación: {elm.relacion} <br />
            {elm.email} <br />
            {elm.telefono}
          </p>
        </div>
      </div>
    ));
  }
}
