import React from 'react'

export default class FileChooser extends React.Component{
    render() {
        return (
            <div className={'fileChooser text-center position-relative d-flex justify-content-center align-items-center'}>
                <label>{this.props.file ? this.props.file.name : 'Añadir un archivo'}</label>
                <input 
                    accept="image/x-png,image/gif,image/jpeg" 
                    type="file" 
                    className="form-control-file opacity-0 position-absolute top-0 start-0 w-100% h-100" 
                    value='' 
                    onChange={this.props.onChange} />
            </div>            
        )
    }
}