import * as Axios from "../libs/Axios/Axios";

const path = "/empresa";

export default class Empresa {
  static list() {
    return Axios.get(path);
  }

  static get(id_empresa) {
    return Axios.get(`${path}/${id_empresa}`);
  }

  static async add(empresa) {
    return Axios.post(path, empresa);
  }

  static tiposList() {
    return Axios.get(path + "/tipo");
  }

  static domicilioCondicionList() {
    return Axios.get(path + "/domicilio-condicion");
  }

  static actividadesComercialesList() {
    return Axios.get(path + "/actividad-comercial");
  }

  static buscar(key) {
    return Axios.get(`${path}?buscar=${key}`);
  }

  static contactoAdd(contacto) {
    return Axios.post(`${path}/contacto`, contacto);
  }

  static contactoUpdate(contacto) {
    return Axios.put(
      `${path}/contacto/${contacto.id_persona_contacto}`,
      contacto
    );
  }
}
