import React from 'react'
import { addNotification } from '../../components/Notifications/Notifications'
import OrdenesCompra from './OrdenesCompra'
import OrdenCompra from '../../services/OrdenCompra'
export default class OrdenesCompraContainer extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            modo: 1, // 1 = lista, 2 = detalle
            items: [],
            id_proveedor: undefined
        }
        this.handleClickNuevo = this.handleClickNuevo.bind(this)
        this.handleSubmitAniadirItem = this.handleSubmitAniadirItem.bind(this)
        this.handleClickDeleteItem = this.handleClickDeleteItem.bind(this)
        this.handleSubmitOC = this.handleSubmitOC.bind(this)
        this.handleChangeProveedor = this.handleChangeProveedor.bind(this)
        this.handleDoubleClickListItem = this.handleDoubleClickListItem.bind(this)
    }
    
    render() {
        return <OrdenesCompra
            ordenCompra={this.state.ordenCompra}
            items={this.state.items}
            modo={this.state.modo} 
            onClickNuevo={this.handleClickNuevo}
            onSubmitAniadirItem={this.handleSubmitAniadirItem}
            onClickDeleteRow={this.handleClickDeleteItem}
            onSubmitOC={this.handleSubmitOC}
            onChangeProveedor={this.handleChangeProveedor}
            onDoubleClickListItem={this.handleDoubleClickListItem}
            />
    }

    async handleSubmitOC(){
        if(await this.validar()){
            try{
                const orden_compra = this.state.items.map(item => {
                    return {id_combinacion: item.id_combinacion, cantidad: item.cantidad, precio: item.precio}
                })
                const data = await OrdenCompra.add(this.state.id_proveedor, orden_compra)
                if(data.complete){
                    this.setState({ modo: 1 })
                    addNotification(`Orden de Compra #${data.id_orden_compra} registrada`, 'bg-success')
                }else{
                    addNotification(data.err, 'bg-warning')    
                }
            }catch(err){
                addNotification('Error al registrar Orden de Compra', 'bg-danger')
            }
        }
    }    

    handleSubmitAniadirItem(combinacion){
        const idx = this.state.items.findIndex(elm => elm.id_combinacion === combinacion.id_combinacion)
        if(idx === -1){
            this.setState({ items: [...this.state.items, combinacion] })
        }else{
            const items = this.state.items
            items[idx] = {
                ...items[idx],
                cantidad: items[idx].cantidad + combinacion.cantidad,
                precio: combinacion.precio
            }
            this.setState({
                items
            })
        }
    }

    handleChangeProveedor(id_proveedor){
        this.setState({ 
            id_proveedor
        })
    }

    handleClickDeleteItem(evt){
        const id_combinacion = Number(evt.currentTarget.getAttribute('data-id'))
        const idx = this.state.items.findIndex(elm => elm.id_combinacion === id_combinacion)
        const items = this.state.items
        items.splice(idx, 1)
        this.setState({ items })
    }

    handleClickNuevo(){
        this.setState({
            modo: 2
        })
    }

    async handleDoubleClickListItem(evt){
        const id_orden_compra = evt.currentTarget.getAttribute('data-id')        

        try{
            const data = await OrdenCompra.get(id_orden_compra)
            if(data.complete){
                this.setState({ 
                    ordenCompra: {
                        id_orden_compra: data.results[0].id_orden_compra,
                        id_proveedor: data.results[0].id_proveedor
                    },
                    items: data.results,
                    modo: 2
                })
            }else{
                addNotification(data.err, 'bg-warning')
            }        
        }catch(err){
            addNotification('Error al obtener Orden de Compra', 'bg-danger')
        }
        
    }

    async validar(){
        let result = true

        if(this.state.items.length < 1) {
            await addNotification('Agregue Items a su orden de compra', 'bg-warning')
            result = false
        }

        if(this.state.items.find(elm => elm.cantidad < 1 )) {
            await addNotification('No puede agregar Items con cantidad 0', 'bg-warning')
            result = false
        }

        if(this.state.id_proveedor === undefined){
            await addNotification('Seleccione un proveedor', 'bg-warning')
            result = false
        }

        return result
    }

}