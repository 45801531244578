import React from 'react'
import MovimientoAlmacenAniadir from './MovimientoAlmacenAniadir'
import MovimientoAlmacenMotivo from '../../services/MovimientoAlmacenMotivo'
import OrdenCompra from '../../services/OrdenCompra'
import Trabajador from '../../services/Trabajador'

export default class MovimientoAlmacenAniadirContainer extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            tipos: [{id: 1, descripcion: 'Ingresos' }, {id: 2, descripcion: 'Salidas' }],
            motivos: [],
            ordenes_compra: [],
            trabajadores: [],
            nuevo: {
                tipo: 1,
                id_motivo: undefined,                
                id_orden_compra: undefined,
                guia_remision: undefined,
                id_trabajador: undefined
            }
        }
        this.handleChangeTipo = this.handleChangeTipo.bind(this)
        this.handleChangeMotivo = this.handleChangeMotivo.bind(this)
        this.handleChangeOrdenCompra = this.handleChangeOrdenCompra.bind(this)
        this.handleChangeFileGuia = this.handleChangeFileGuia.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeTrabajador = this.handleChangeTrabajador.bind(this)
    }

    render() {
        return <MovimientoAlmacenAniadir 
                    tipos={this.state.tipos}
                    motivos={this.state.motivos}
                    ordenesCompra={this.state.ordenes_compra}
                    tipo={this.state.nuevo.tipo}
                    idMotivo={this.state.nuevo.id_motivo}
                    trabajadores={this.state.trabajadores}
                    onChangeTipo={this.handleChangeTipo} 
                    onChangeMotivo={this.handleChangeMotivo}
                    onChangeOrdenCompra={this.handleChangeOrdenCompra}
                    onChangeFileGuia={this.handleChangeFileGuia}
                    onChangeTrabajador={this.handleChangeTrabajador}
                    onSubmit={this.handleSubmit} />
    }

    componentDidMount(){
        this.getMotivos()
        this.getOrdenesCompra()
    }

    handleChangeTipo(id){
        this.setState({
            nuevo: {
                ...this.state.nuevo,
                tipo: id,
                id_motivo: undefined,                
                id_orden_compra: undefined,
                guia_remision: undefined,
                id_trabajador: undefined
            },
            trabajadores: []
        }, () => this.getMotivos() )
    }

    handleChangeTrabajador(id_trabajador){
        this.setState({
            nuevo: {
                ...this.state.nuevo,
                id_trabajador
            }
        })
    }

    handleChangeMotivo(id){
        this.setState({
            trabajadores: [],
            nuevo: {
                ...this.state.nuevo,
                id_motivo: id,
                id_orden_compra: undefined,
                id_trabajador: undefined
            },
        }, () => {
            if(this.state.nuevo.id_motivo === 8){
                this.getTrabajadores()
            }
        })
    }

    handleChangeFileGuia(base64){
        this.setState({
            nuevo: {
                ...this.state.nuevo,
                guia_remision: base64
            },
        })
    }

    handleSubmit(){
        this.props.onSubmit(this.state.nuevo)
    }

    async getTrabajadores(){
        const data = await Trabajador.list()
        const trabajadores = data.results
        this.setState({ trabajadores })
    }

    async getMotivos(){
        const data = await MovimientoAlmacenMotivo.listByTipo(this.state.nuevo.tipo)
        const motivos = data.results
        this.setState({ motivos })
    }

    async getOrdenesCompra(){
        const data = await OrdenCompra.list()
        let ordenes_compra = data.results
        ordenes_compra = ordenes_compra.map(elm => {
            return {...elm, descripcion: `#${elm.id_orden_compra}`}
        })
        this.setState({ ordenes_compra })
    }

    handleChangeOrdenCompra(id){
        this.setState({
            nuevo: {
                ...this.state.nuevo,
                id_orden_compra: id
            },
        })
    }

}