import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Auth from "./Auth";

import "./components/Notifications/Notifications";
const id_usuario = localStorage.getItem("id_usuario");

let main = <App />;

if (!id_usuario) {
  main = <Auth />;
}

ReactDOM.render(main, document.getElementById("root"));
