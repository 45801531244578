import React from "react";
import EmpresaNuevo from "./EmpresaNuevo";
import Empresa from "../../services/Empresa";
import Ubicacion from "../../services/Ubicacion";
import Misc from "../../helpers/Misc";
import { addNotification } from "../Notifications/Notifications";

export default class EmpresaNuevoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      empresa: {
        RUC: undefined,
        razon_social: undefined,
        id_empresa_tipo: undefined,
        id_domicilio_fiscal_condicion: undefined,
        fecha_inscripcion: undefined,
        id_distrito_direccion_fiscal: undefined,
        id_tipo_via_direccion_fiscal: undefined,
        via_direccion_fiscal: undefined,
        numeracion_via_direccion_fiscal: undefined,
        direccion_fiscal: undefined,
        id_actividad_comercial: undefined,
      },
      tipos_empresa: [],
      domicilio_condiciones: [],
      departamentos: [],
      provincias: [],
      distritos: [],
      tipos_via: [],
      actividades_comerciales: [],
    };
    this.init();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.ruc) {
      this.setState(
        {
          empresa: {
            ...this.state.empresa,
            RUC: newProps.ruc,
          },
        },
        () => {
          this.buscarEmpresa();
        }
      );
    }
  }

  render() {
    return (
      <EmpresaNuevo
        tipos_empresa={this.state.tipos_empresa}
        empresa={this.state.empresa}
        domicilio_condiciones={this.state.domicilio_condiciones}
        departamentos={this.state.departamentos}
        provincias={this.state.provincias}
        distritos={this.state.distritos}
        tipos_via={this.state.tipos_via}
        actividades_comerciales={this.state.actividades_comerciales}
        onChangeEmpresaTipos={this.handleChangeEmpresaTipos}
        onChangeDomicilioCondicion={this.handleChangeDomicilioCondicion}
        onChangeFechaInscripcion={this.handlehangeFechaInscripcion}
        onChangeDepartamento={this.handleChangeDepartamento}
        onChangeProvincia={this.handleChangeProvincia}
        onChangeDistrito={this.handleChangeDistrito}
        onChangeTipoVia={this.handleChangeTipoVia}
        onChangeVia={this.handleChangeVia}
        onChangeNumeracionVia={this.handleChangeNumeracionVia}
        onChangeDireccionFiscal={this.handleChangeDireccionFiscal}
        onChangeActividadComercial={this.handleChangeActividadComercial}
        onChangeRUC={this.handleChangeRUC}
        onChangeRazonSocial={this.handleChangeRazonSocial}
        onSubmit={this.handleSubmit}
        buttonText={this.props.buttonText}
      />
    );
  }

  init() {
    /**
     * START EVENTS
     */
    this.handleChangeEmpresaTipos = this.handleChangeEmpresaTipos.bind(this);
    this.handleChangeDomicilioCondicion =
      this.handleChangeDomicilioCondicion.bind(this);
    this.handlehangeFechaInscripcion =
      this.handlehangeFechaInscripcion.bind(this);
    this.handleChangeDepartamento = this.handleChangeDepartamento.bind(this);
    this.handleChangeProvincia = this.handleChangeProvincia.bind(this);
    this.handleChangeDistrito = this.handleChangeDistrito.bind(this);
    this.handleChangeTipoVia = this.handleChangeTipoVia.bind(this);
    this.handleChangeVia = this.handleChangeVia.bind(this);
    this.handleChangeNumeracionVia = this.handleChangeNumeracionVia.bind(this);
    this.handleChangeDireccionFiscal =
      this.handleChangeDireccionFiscal.bind(this);
    this.handleChangeActividadComercial =
      this.handleChangeActividadComercial.bind(this);
    this.handleChangeRUC = this.handleChangeRUC.bind(this);
    this.handleChangeRazonSocial = this.handleChangeRazonSocial.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    /**
     * END EVENTS
     */

    this.getTiposEmpresa();
    this.getDomicilioCondiciones();
    this.getDepartamentos();
    this.getTiposVia();
    this.getActividadesComerciales();
  }

  async getActividadesComerciales() {
    const { results: actividades_comerciales } =
      await Empresa.actividadesComercialesList();
    this.setState({ actividades_comerciales });
  }

  async getDomicilioCondiciones() {
    const { results: domicilio_condiciones } =
      await Empresa.domicilioCondicionList();
    this.setState({ domicilio_condiciones });
  }

  async getTiposEmpresa() {
    const { results: tipos_empresa } = await Empresa.tiposList();
    this.setState({ tipos_empresa });
  }

  async getDepartamentos() {
    const { results: departamentos } = await Ubicacion.departamentosList();
    this.setState({ departamentos });
  }

  async getProvincias(id_departamento) {
    const { results: provincias } = await Ubicacion.provinciasList(
      id_departamento
    );
    this.setState({
      empresa: {
        ...this.state.empresa,
        id_departamento_direccion_fiscal: id_departamento,
      },
      provincias,
    });
  }

  async getTiposVia() {
    const { results: tipos_via } = await Ubicacion.tiposViaList();
    this.setState({ tipos_via });
  }

  async getDistritos(id_provincia) {
    const { results: distritos } = await Ubicacion.distritosList(id_provincia);
    this.setState({
      empresa: {
        ...this.state.empresa,
        id_provincia_direccion_fiscal: id_provincia,
      },
      distritos,
    });
  }

  async buscarEmpresa() {
    const response = await Empresa.buscar(this.state.empresa.RUC);
    if (response.complete && response.results.length > 0) {
      this.setState({
        empresa: {
          ...this.state.empresa,
          razon_social: response.results[0].razon_social,
          id_empresa: response.results[0].id_persona,
          id_empresa_tipo: response.results[0].id_empresa_tipo,
          id_domicilio_fiscal_condicion:
            response.results[0].id_domicilio_fiscal_condicion,
          fecha_inscripcion: response.results[0].fecha_inscripcion,
          id_departamento_direccion_fiscal:
            response.results[0].id_departamento_direccion_fiscal,
          id_provincia_direccion_fiscal:
            response.results[0].id_provincia_direccion_fiscal,
          id_distrito_direccion_fiscal:
            response.results[0].id_distrito_direccion_fiscal,
          id_tipo_via_direccion_fiscal:
            response.results[0].id_tipo_via_direccion_fiscal,
          via_direccion_fiscal: response.results[0].via_direccion_fiscal,
          numeracion_via_direccion_fiscal:
            response.results[0].numeracion_via_direccion_fiscal,
          direccion_fiscal: response.results[0].direccion_fiscal,
          id_actividad_comercial: response.results[0].id_actividad_comercial,
        },
      });
    }
  }

  handleChangeEmpresaTipos(id_empresa_tipo) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        id_empresa_tipo,
      },
    });
  }

  handleChangeDomicilioCondicion(id_domicilio_fiscal_condicion) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        id_domicilio_fiscal_condicion,
      },
    });
  }

  handlehangeFechaInscripcion(evt) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        fecha_inscripcion: evt.target.value,
      },
    });
  }

  handleChangeDepartamento(id_departamento) {
    this.getProvincias(id_departamento);
  }

  handleChangeProvincia(id_provincia) {
    this.getDistritos(id_provincia);
  }

  handleChangeDistrito(id_distrito) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        id_distrito_direccion_fiscal: id_distrito,
      },
    });
  }

  handleChangeTipoVia(id_tipo_via) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        id_tipo_via_direccion_fiscal: id_tipo_via,
      },
    });
  }

  handleChangeVia(evt) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        via_direccion_fiscal: evt.target.value,
      },
    });
  }

  handleChangeNumeracionVia(evt) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        numeracion_via_direccion_fiscal: evt.target.value,
      },
    });
  }

  handleChangeDireccionFiscal(evt) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        direccion_fiscal: evt.target.value,
      },
    });
  }

  handleChangeActividadComercial(id_actividad_comercial) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        id_actividad_comercial,
      },
    });
  }

  handleChangeRUC(evt) {
    this.setState(
      {
        empresa: {
          ...this.state.empresa,
          RUC: evt.target.value,
        },
      },
      () => {
        if (Misc.isRUC(this.state.empresa.RUC)) {
          this.buscarEmpresa();
        }
      }
    );
  }

  handleChangeRazonSocial(evt) {
    this.setState({
      empresa: {
        ...this.state.empresa,
        razon_social: evt.target.value,
      },
    });
  }

  async handleSubmit() {
    if (this.state.empresa.id_empresa === undefined) {
      if (await this.validarCampos()) {
        this.agregarEmpresa();
      }
    } else {
      this.props.onSubmit(this.state.empresa.id_empresa);
    }
  }

  async validarCampos() {
    let result = true;
    if (
      Misc.isEmpty(this.state.empresa.RUC) ||
      !Misc.isRUC(this.state.empresa.RUC)
    ) {
      result = false;
      await addNotification(
        "El campo RUC esta vacio o tiene un formato no válido.",
        "bg-warning"
      );
    }
    if (Misc.isEmpty(this.state.empresa.razon_social)) {
      result = false;
      await addNotification("El campo Razón Social esta vacio.", "bg-warning");
    }
    if (Misc.isEmpty(this.state.empresa.id_empresa_tipo)) {
      result = false;
      await addNotification("Seleccione un Tipo de Empresa", "bg-warning");
    }
    if (Misc.isEmpty(this.state.empresa.id_domicilio_fiscal_condicion)) {
      result = false;
      await addNotification("Seleccione una Condición", "bg-warning");
    }
    if (Misc.isEmpty(this.state.empresa.fecha_inscripcion)) {
      result = false;
      await addNotification(
        "Seleccione una Fecha de Inscripción",
        "bg-warning"
      );
    }
    if (Misc.isEmpty(this.state.empresa.id_distrito_direccion_fiscal)) {
      result = false;
      await addNotification("Seleccione un Distrito", "bg-warning");
    }
    if (Misc.isEmpty(this.state.empresa.id_tipo_via_direccion_fiscal)) {
      result = false;
      await addNotification("El campo Tipo de Vía esta vacio", "bg-warning");
    }
    if (Misc.isEmpty(this.state.empresa.via_direccion_fiscal)) {
      result = false;
      await addNotification("El campo Vía esta vacio", "bg-warning");
    }
    if (Misc.isEmpty(this.state.empresa.direccion_fiscal)) {
      result = false;
      await addNotification(
        "El campo Dirección Fiscal esta vacio",
        "bg-warning"
      );
    }
    if (Misc.isEmpty(this.state.empresa.id_actividad_comercial)) {
      result = false;
      await addNotification("Seleccione una Actividad comercial", "bg-warning");
    }
    return result;
  }

  async agregarEmpresa() {
    const response = await Empresa.add({
      ...this.state.empresa,
      numeracion_via_direccion_fiscal: Misc.isEmpty(
        this.state.empresa.numeracion_via_direccion_fiscal
      )
        ? undefined
        : this.state.empresa.numeracion_via_direccion_fiscal,
    });
    if (response.complete) {
      this.props.onSubmit(response.id_empresa);
      this.setState({
        empresa: {
          ...this.state.empresa,
          id_empresa: response.id_empresa,
        },
      });
    }
  }
}
