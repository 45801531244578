import React from "react";
import Modal from "./Modal";
import * as bootstrap from "bootstrap/dist/js/bootstrap";

export default class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Date.now(),
      elm: undefined,
    };
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
  }

  render() {
    return (
      <Modal
        id={this.state.id}
        content={this.props.children}
        size={this.props.size}
        onSubmitModal={this.handleSubmitModal}
        title={this.props.title}
      />
    );
  }

  componentDidMount() {
    const elm = document.getElementById(`${this.state.id}`);
    const modal = new bootstrap.Modal(elm, {
      keyboard: false,
    });
    this.setState(
      {
        modal,
      },
      () => {
        this.state.modal.show();
        elm.addEventListener("hidden.bs.modal", () => {
          if (this.props.onHideModal) {
            this.props.onHideModal();
          }
        });
      }
    );
  }

  handleSubmitModal() {
    this.state.modal.hide();
    this.props.onSubmitModal();
  }
}
