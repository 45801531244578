import React from "react";

export default class Modal extends React.Component {
  render() {
    const size =
      this.props.size === undefined ? "" : `modal-${this.props.size}`;

    return (
      <div className="modal fade show" tabIndex="-1" id={this.props.id}>
        <div className={"modal-dialog modal-dialog-centered " + size}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">{this.props.content}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.props.onSubmitModal}
              >
                Generar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
