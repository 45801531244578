import React from "react";
import Login from "./Login";
import Auth from "../../services/Auth";
import { addNotification } from "../Notifications/Notifications";

export default class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Login
        email={this.state.email}
        password={this.state.password}
        onChangeEmail={this.handleChangeEmail}
        onChangePassword={this.handleChangePassword}
        onSubmit={this.handleSubmit}
      />
    );
  }

  handleChangeEmail(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  handleChangePassword(evt) {
    this.setState({
      password: evt.target.value,
    });
  }

  async handleSubmit(evt) {
    evt.preventDefault();
    try {
      await Auth.login({
        email: this.state.email,
        contrasenia: this.state.password,
      });
      window.location.reload();
    } catch (err) {
      if (err.response) {
        addNotification(err.response.data.err, "bg-danger");
      } else {
        console.log(err);
        addNotification("Error al iniciar sesión.", "bg-danger");
      }
    }
  }
}
