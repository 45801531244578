import React from "react";
import TabPane from "./TabPane";

export default class TabPaneContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.items.find((elm) => elm.id === this.props.active),
    };
    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleClickSiguiente = this.handleClickSiguiente.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.active !== this.props.active) {
      const active = this.props.items.find(
        (item) => item.id === this.props.active
      );
      this.setState({
        active,
      });
    }
  }

  render() {
    return (
      <TabPane
        active={this.state.active}
        items={this.props.items}
        onClickItem={this.handleClickItem}
        onClicksiguiente={this.handleClickSiguiente}
      />
    );
  }

  handleClickSiguiente() {
    this.setState({ active: this.props.items[1] });
  }

  handleClickItem(item) {
    if (this.props.onClickItem) {
      this.props.onClickItem(item.id);
    }
  }
}
