import React from "react";
import CotizacionNuevo from "./CotizacionNuevo";
import EmpresaNuevoContainer from "../EmpresaNuevo/EmpresaNuevoContainer";
import EmpresaContactoContainer from "../EmpresaContacto/EmpresaContactoContainer";
import Cotizacion from "../../services/Cotizacion";
import { ReactComponent as ExitoImg } from "../../images/Exito.svg";

export default class CotizacionNuevoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitEmpresa = this.handleSubmitEmpresa.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleChangeIdContacto = this.handleChangeIdContacto.bind(this);
    this.handleClickEmpresaContactoSiguiente =
      this.handleClickEmpresaContactoSiguiente.bind(this);
    this.handleClickCotizar = this.handleClickCotizar.bind(this);
    this.handleClickAprobar = this.handleClickAprobar.bind(this);
    this.handleClickGenerarContrato =
      this.handleClickGenerarContrato.bind(this);
    this.handleClickCerrarContrato = this.handleClickCerrarContrato.bind(this);
    this.state = { cotizacion: this.props.cotizacion };
    this.state = {
      id_empresa: undefined,
      id_contacto: undefined,
      cotizacion: this.props.cotizacion,
      items: [
        {
          id: "tab-empresa",
          title: "Empresa",
          content: (
            <EmpresaNuevoContainer
              buttonText="Siguiente"
              onSubmit={this.handleSubmitEmpresa}
            />
          ),
        },
        {
          id: "tab-contacto",
          title: "Contacto",
          content: (
            <EmpresaContactoContainer
              buttonText="Siguiente"
              idEmpresa={this.state.id_empresa}
              onChangeIdContacto={this.handleChangeIdContacto}
              onClickSiguiente={this.handleClickEmpresaContactoSiguiente}
            />
          ),
        },
        {
          id: "tab-final",
          title: "Final",
          content: this.state.cotizacion ? this.mostrarFinal() : "",
        },
      ],
      active: this.state.cotizacion ? "tab-final" : "tab-empresa",
    };
    if (this.state.cotizacion) {
      this.getCotizacion();
    }
  }

  render() {
    return (
      <CotizacionNuevo
        cotizacion={this.state.cotizacion}
        items={this.state.items}
        active={this.state.active}
        onClickItem={this.handleClickItem}
      />
    );
  }

  handleSubmitEmpresa(id_empresa) {
    let items = this.state.items;

    const contactoItem = {
      ...items[1],
      content: (
        <EmpresaContactoContainer
          buttonText="Siguiente"
          idEmpresa={id_empresa}
          onChangeIdContacto={this.handleChangeIdContacto}
          onClickSiguiente={this.handleClickEmpresaContactoSiguiente}
        />
      ),
    };

    items[1] = contactoItem;

    this.setState({
      active: "tab-contacto",
      items,
      id_empresa,
    });
  }

  mostrarFinal() {
    const cerrar =
      localStorage.getItem("id_usuario") === "5" ? (
        <button
          className="btn btn-primary rounded-pill d-block mb-3 btn-lg me-auto ms-auto"
          onClick={this.handleClickCerrarContrato}
        >
          G. Cuenta
        </button>
      ) : (
        ""
      );

    const accionBtn =
      this.state.cotizacion.estado === 0 ? (
        <button
          className="btn btn-primary rounded-pill d-block mb-3 btn-lg me-auto ms-auto"
          onClick={this.handleClickAprobar}
          style={{ width: "120px" }}
        >
          Aprobar
        </button>
      ) : this.state.cotizacion.estado === 1 ? (
        <button
          className="btn btn-primary rounded-pill d-block mb-3 btn-lg me-auto ms-auto text-nowrap"
          onClick={this.props.onClickContrato}
          style={{ width: "120px" }}
        >
          Contrato
        </button>
      ) : (
        ""
      );
    return (
      <div className="text-center pt-5">
        <ExitoImg className="mb-4" />
        <p className="text-center mb-4">
          {this.state.cotizacion.estado === 0
            ? "PENDIENTE DE APROBACIÓN"
            : "APROBADO"}
        </p>
        {accionBtn}
        {cerrar}
        <button
          className="btn btn-primary rounded-pill d-block mb-3 btn-lg me-auto ms-auto"
          onClick={this.props.onClickDescargar}
          style={{ width: "120px" }}
        >
          Descargar
        </button>
        <button
          className="btn btn-outline-primary rounded-pill mb-3 btn-lg me-auto ms-auto d-block"
          style={{ width: "120px" }}
          onClick={this.props.onClickCerrar}
        >
          Salir
        </button>
      </div>
    );
  }

  handleClickEmpresaContactoSiguiente() {
    let item = this.state.items[2];
    item.content = (
      <div className="text-center pt-5">
        <ExitoImg className="mb-4" />
        <p className="text-center mb-4">ESTA LISTO PARA COTIZAR</p>
        <button
          className="btn btn-primary rounded-pill d-block mb-3 btn-lg me-auto ms-auto"
          onClick={this.handleClickCotizar}
          style={{ width: "120px" }}
        >
          Cotizar
        </button>
        <button
          className="btn btn-outline-primary rounded-pill mb-3 btn-lg me-auto ms-auto d-block"
          style={{ width: "120px" }}
          onClick={this.props.onClickCerrar}
        >
          Salir
        </button>
      </div>
    );
    this.setState({ active: "tab-final" });
  }

  async handleClickAprobar() {
    await Cotizacion.aprobar(this.props.cotizacion.id_cotizacion);

    this.setState(
      { cotizacion: { ...this.state.cotizacion, estado: 1 } },
      () => {
        let items = this.state.items;
        const item = { ...items[2], content: this.mostrarFinal() };
        items[2] = item;
        this.setState({
          items: items,
        });
        this.props.onClickAprobar();
      }
    );
  }

  handleClickItem(id_item) {
    let items = this.state.items;
    items[0] = {
      id: "tab-empresa",
      title: "Empresa",
      content: (
        <EmpresaNuevoContainer
          buttonText="Siguiente"
          ruc={this.state.cotizacion ? this.state.cotizacion.ruc : undefined}
          onSubmit={this.handleSubmitEmpresa}
        />
      ),
    };
    console.log(this.state.id_empresa);
    items[1] = {
      id: "tab-contacto",
      title: "Contacto",
      content: (
        <EmpresaContactoContainer
          buttonText="Siguiente"
          idEmpresa={this.state.id_empresa}
          onChangeIdContacto={this.handleChangeIdContacto}
          onClickSiguiente={this.handleClickEmpresaContactoSiguiente}
        />
      ),
    };
    this.setState({
      active: id_item,
      items,
    });
  }

  handleChangeIdContacto(id_contacto) {
    this.setState({ id_contacto: id_contacto });
  }

  handleClickCotizar() {
    this.props.onClickCotizar({
      id_empresa: this.state.id_empresa,
      id_contacto: this.state.id_contacto,
    });
  }

  handleClickGenerarContrato() {
    console.log("handleClickGenerarContrato");
  }

  handleClickCerrarContrato() {
    console.log("Gen. Cuenta");
  }

  async getCotizacion() {
    const { results: cotizacion } = await Cotizacion.get(
      this.state.cotizacion.id_cotizacion
    );
    this.setState({
      cotizacion: { ...this.state.cotizacion, ruc: cotizacion[0].ruc },
      id_empresa: this.state.cotizacion.id_persona,
    });
  }
}
