import React from "react";
import SelectSearchContainer from "../SelectSearch/SelectSearchContainer";

export default class ServicioAniadir extends React.Component {
  render() {
    return (
      <div className={"ps-4 pe-4"}>
        <div>
          <div className="form-group mb-2">
            <label>Departamento - Ubicación</label>
            <SelectSearchContainer
              value={this.props.nuevo.id_departamento_ubicacion}
              options={this.props.departamentos}
              id="departamento"
              valueKey="id_departamento"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeDepartamento}
            />
          </div>
          <div className="form-group mb-2">
            <label>Provincia - Ubicación</label>
            <SelectSearchContainer
              value={this.props.nuevo.id_provincia_ubicacion}
              options={this.props.provincias}
              id="provincia"
              valueKey="id_provincia"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeProvincia}
            />
          </div>
          <div className="form-group mb-2">
            <label>Distrito - Ubicación</label>
            <SelectSearchContainer
              value={this.props.nuevo.id_distrito_ubicacion}
              options={this.props.distritos}
              id="distrito"
              valueKey="id_distrito"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeDistrito}
            />
          </div>
          <div className="form-group mb-2">
            <label>Tipo de Vía - Ubicación</label>
            <SelectSearchContainer
              value={this.props.nuevo.id_tipo_via_ubicacion}
              options={this.props.tipos_via}
              id="tipo_via"
              valueKey="id_tipo_via"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeTipoVia}
            />
          </div>
          <div className="row">
            <div className="col-8">
              <div className="form-group mb-2">
                <label>Vía</label>
                <input
                  className="form-control"
                  placeholder=""
                  onChange={this.props.onChangeVia}
                  value={this.props.nuevo.via_ubicacion || ""}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-2">
                <label>Número</label>
                <input
                  className="form-control"
                  placeholder=""
                  onChange={this.props.onChangeNumeroVia}
                  value={this.props.nuevo.numeracion_via_ubicacion || ""}
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-2">
            <label>Descripción - Ubicación</label>
            <input
              className="form-control"
              placeholder=""
              onChange={this.props.onChangeDescripcion}
              value={this.props.nuevo.descripcion_ubicacion || ""}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group mb-2">
                <label>Producto</label>
                <SelectSearchContainer
                  value={this.props.nuevo.id_producto}
                  options={this.props.productos}
                  id="productos"
                  valueKey="id_item"
                  displayKey="abreviatura"
                  placeholder=""
                  onChange={this.props.onChangeProducto}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group mb-2">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  checked={this.props.nuevo.armado}
                  onChange={this.props.onChangeArmado}
                  id="cbArmado"
                />
                <label className="form-check-label" htmlFor="cbArmado">
                  Armado
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group mb-2">
                <label>Cant.</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder=""
                  value={this.props.nuevo.cantidad || ""}
                  onChange={this.props.onChangeCantidad}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-2">
                <label>Precio.</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder=""
                  value={this.props.nuevo.precio || ""}
                  onChange={this.props.onChangePrecio}
                />
              </div>
            </div>
          </div>
          <div className="aside-footer position-fixed end-0 bottom-0 p-3">
            <button
              className="btn btn-primary rounded-pill"
              onClick={this.props.onSubmit}
            >
              Añadir
            </button>
          </div>
        </div>
        <div className="table-component mt-3 d-none">
          <table className="table mini">
            <thead>
              <tr>
                <th>Prod.</th>
                <th>Cant.</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1P24LD</td>
                <td>9</td>
                <td>S/3100.00</td>
              </tr>
              <tr>
                <td>1P24LD</td>
                <td>9</td>
                <td>S/3100.00</td>
              </tr>
              <tr>
                <td>1P24LD</td>
                <td>9</td>
                <td>S/3100.00</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">DESCUENTO A APROBAR</td>
                <td>0%</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}
