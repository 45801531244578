import * as Axios from '../libs/Axios/Axios'

const path = '/producto/combinacion'

export default class Combinacion{

    static list(){
        return Axios.get(path)
    }

    static listByIdProducto(id_producto){
        return Axios.get(path, [{key: 'id_producto', value: id_producto}])
    }

}