import React from "react";
import SelectSearch from "./SelectSearch";

export default class SelectSearchContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayOptions: this.props.options,
      displayValue: "",
    };

    this.handleOnClickOption = this.handleOnClickOption.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickClean = this.handleClickClean.bind(this);
  }

  render() {
    return (
      <SelectSearch
        displayValue={this.state.displayValue}
        id={this.props.id}
        options={this.state.displayOptions}
        valueKey={this.props.valueKey}
        displayKey={this.props.displayKey}
        onClickOption={this.handleOnClickOption}
        onChangeInput={this.handleChangeInput}
        onClickClean={this.handleClickClean}
        placeholder={this.props.placeholder}
      />
    );
  }

  componentDidMount() {
    if (this.props.value && this.props.options.length > 0) {
      this.setValue();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.options !== prevProps.options ||
      this.props.value !== prevProps.value
    ) {
      let displayValue = "";

      if (this.props.value !== undefined) {
        const select = this.props.options.find(
          (itm) => String(itm[this.props.valueKey]) === String(this.props.value)
        );
        if (select !== undefined) {
          displayValue = select[this.props.displayKey];
          this.props.onChange(select[this.props.valueKey], this.props.id);
        }
      }

      this.setState({
        displayOptions: this.props.options,
        displayValue,
      });
    }
  }

  setValue() {
    const select = this.props.options.find(
      (itm) => String(itm[this.props.valueKey]) === String(this.props.value)
    );
    this.setState({
      displayValue: select[this.props.displayKey],
    });
  }

  getfilterOptions() {
    this.setState({
      displayOptions: this.props.options,
    });
  }

  handleOnClickOption(evt) {
    const id = Number(evt.currentTarget.getAttribute("data-id"));
    const option = this.props.options.find(
      (elm) => elm[this.props.valueKey] === id
    );
    const displayValue = option[this.props.displayKey];

    this.setState(
      {
        displayValue,
      },
      () => {
        this.props.onChange(id, this.props.id);
      }
    );
  }

  handleClickClean() {
    this.setState(
      {
        displayValue: "",
      },
      () => {
        this.props.onChange(undefined, this.props.id);
      }
    );
  }

  handleChangeInput(evt) {
    const displayOptions = this.props.options.filter((elm) =>
      elm[this.props.displayKey]
        .toUpperCase()
        .includes(evt.target.value.toUpperCase())
    );

    this.setState({
      displayValue: evt.target.value,
      displayOptions,
    });
  }
}
