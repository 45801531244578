import React from "react";
import SelectSearchContainer from "../SelectSearch/SelectSearchContainer";
import Misc from "../../helpers/Misc";

export default class EmpresaNuevo extends React.Component {
  render() {
    return (
      <div className="">
        <div className="form-group mb-2 position-relative">
          <label>RUC</label>
          <input
            type="text"
            className="form-control"
            value={this.props.empresa.RUC || ""}
            onChange={this.props.onChangeRUC}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Razon Social</label>
          <input
            type="text"
            className="form-control"
            value={this.props.empresa.razon_social || ""}
            onChange={this.props.onChangeRazonSocial}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Tipo de Empresa</label>
          <SelectSearchContainer
            options={this.props.tipos_empresa}
            id="empresa_tipos"
            valueKey="id_empresa_tipo"
            displayKey="abreviatura"
            value={this.props.empresa.id_empresa_tipo}
            onChange={this.props.onChangeEmpresaTipos}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Condición</label>
          <SelectSearchContainer
            options={this.props.domicilio_condiciones}
            id="domicilio_condicion"
            valueKey="id_domicilio_fiscal_condicion"
            displayKey="descripcion"
            value={this.props.empresa.id_domicilio_fiscal_condicion}
            onChange={this.props.onChangeDomicilioCondicion}
          />
        </div>
        <div className="form-group mb-2">
          <label>Fecha de Inscripción</label>
          <input
            type="date"
            className="form-control datepicker"
            placeholder="dd-mm-aaaa"
            max={Misc.getDate()}
            value={this.props.empresa.fecha_inscripcion || ""}
            onChange={this.props.onChangeFechaInscripcion}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Departamento - Dirección Fiscal</label>
          <SelectSearchContainer
            options={this.props.departamentos}
            id="departamentos"
            valueKey="id_departamento"
            displayKey="descripcion"
            value={this.props.empresa.id_departamento_direccion_fiscal}
            onChange={this.props.onChangeDepartamento}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Provincia - Dirección Fiscal</label>
          <SelectSearchContainer
            options={this.props.provincias}
            id="provincias"
            valueKey="id_provincia"
            displayKey="descripcion"
            value={this.props.empresa.id_provincia_direccion_fiscal}
            onChange={this.props.onChangeProvincia}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Distrito - Dirección Fiscal</label>
          <SelectSearchContainer
            options={this.props.distritos}
            id="distritos"
            valueKey="id_distrito"
            displayKey="descripcion"
            value={this.props.empresa.id_distrito_direccion_fiscal}
            onChange={this.props.onChangeDistrito}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Tipo de Vía - Dirección Fiscal</label>
          <SelectSearchContainer
            options={this.props.tipos_via}
            id="tipo_via"
            valueKey="id_tipo_via"
            displayKey="descripcion"
            value={this.props.empresa.id_tipo_via_direccion_fiscal}
            onChange={this.props.onChangeTipoVia}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Vía - Dirección Fiscal</label>
          <input
            type="text"
            className="form-control"
            value={this.props.empresa.via_direccion_fiscal || ""}
            onChange={this.props.onChangeVia}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Numeración de Vía - Dirección Fiscal</label>
          <input
            type="text"
            className="form-control"
            value={this.props.empresa.numeracion_via_direccion_fiscal || ""}
            onChange={this.props.onChangeNumeracionVia}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Dirección Fiscal</label>
          <input
            type="text"
            className="form-control"
            value={this.props.empresa.direccion_fiscal || ""}
            onChange={this.props.onChangeDireccionFiscal}
          />
        </div>
        <div className="form-group mb-2 position-relative">
          <label>Actividad Comercial</label>
          <SelectSearchContainer
            options={this.props.actividades_comerciales}
            id="actividad_comercial"
            valueKey="id_actividad_comercial"
            displayKey="descripcion"
            value={this.props.empresa.id_actividad_comercial}
            onChange={this.props.onChangeActividadComercial}
          />
        </div>
        <div className="aside-footer position-fixed end-0 bottom-0 p-3">
          <button
            className="btn btn-primary rounded-pill"
            onClick={this.props.onSubmit}
          >
            {this.props.buttonText}
          </button>
        </div>
      </div>
    );
  }
}
