import React from "react";
import ItemNuevoView from "./ItemNuevoView";
import Producto from "../../services/Producto";

let events = [];

const subscribe = (fun) => {
  events.push(fun);
};

class ItemNuevo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        descripcion: "",
      },
    };
    this.handleChangeDescripcion = this.handleChangeDescripcion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <ItemNuevoView
        item={this.state.item}
        onChangeDescripcion={this.handleChangeDescripcion}
        onSubmit={this.handleSubmit}
      />
    );
  }

  handleChangeDescripcion(evt) {
    this.setState({
      item: { ...this.state.item, descripcion: evt.target.value },
    });
  }

  async handleSubmit() {
    const { descripcion } = this.state.item;
    await Producto.add(descripcion);
    this.props.onSubmit();
    events.forEach((evt) => evt());
  }
}

export { ItemNuevo as Component, subscribe };
