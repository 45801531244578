import React from "react";
import ServicioDetalle from "./ServicioDetalle";
import Cuenta from "../../services/Cuenta";

export default class ServicioDetallecontainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      proveedores: [],
      title: "Nuevo Servicio",
      items: [],
    };
    this.handleClickRow = this.handleClickRow.bind(this);
    this.getSubTotal = this.getSubTotal.bind(this);
    this.getServicioDetalle();
  }

  componentDidMount() {}

  render() {
    return (
      <ServicioDetalle
        id_servicio={this.props.id_servicio}
        items={this.state.items}
        title={this.state.title}
        onClickRow={this.handleClickRow}
        onClickDeleteRow={this.props.onClickDeleteRow}
        subtotal={this.getSubTotal()}
        onSubmit={this.props.onSubmit}
        onChangeProveedor={this.props.onChangeProveedor}
      />
    );
  }

  handleClickRow(evt) {
    // const id_combinacion = evt.currentTarget.getAttribute('data-id')
  }

  getSubTotal() {
    const subtotal = this.state.items.reduce(
      (A, B) => A + B.cantidad * B.precio,
      0
    );
    return subtotal;
  }

  async getServicioDetalle() {
    if (this.props.id_servicio) {
      const { results: items } = await Cuenta.serviciosGet(
        this.props.id_servicio
      );
      this.setState({ items });
    }
  }
}
