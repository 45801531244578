import React from "react";
import ItemsView from "./ItemsView";
import { listen } from "../../components/Aside/AsideContainer";

export default class Items extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aside: undefined,
      id_item: undefined,
    };
    this.handleClickNuevoItem = this.handleClickNuevoItem.bind(this);
    this.handleSubmitNuevo = this.handleSubmitNuevo.bind(this);
    this.handleDoubleClickRowItem = this.handleDoubleClickRowItem.bind(this);
    this.handleClickNuevoCombinacion =
      this.handleClickNuevoCombinacion.bind(this);
    this.handleDoubleClickRowCombinacion =
      this.handleDoubleClickRowCombinacion.bind(this);
    this.handleSubmitCombinacionNuevo =
      this.handleSubmitCombinacionNuevo.bind(this);

    this.clearAside = this.clearAside.bind(this);
    listen(this.clearAside);
  }

  render() {
    return (
      <ItemsView
        aside={this.state.aside}
        idItem={this.state.id_item}
        idCombinacion={this.state.id_combinacion}
        onClickNuevoItem={this.handleClickNuevoItem}
        onSubmitItemNuevo={this.handleSubmitNuevo}
        onDoubleClickRowItem={this.handleDoubleClickRowItem}
        onClickNuevoCombinacion={this.handleClickNuevoCombinacion}
        onDoubleClickRowCombinacion={this.handleDoubleClickRowCombinacion}
        onSubmitCombinacionNuevo={this.handleSubmitCombinacionNuevo}
      />
    );
  }

  handleClickNuevoItem() {
    this.setState({
      aside: "nuevo-item",
    });
  }

  handleSubmitNuevo() {
    this.clearAside();
  }

  handleDoubleClickRowItem(evt) {
    const id_item = evt.currentTarget.getAttribute("data-id");
    this.setState({ id_item });
  }

  handleClickNuevoCombinacion() {
    this.setState({
      aside: "nueva-combinacion",
      id_combinacion: undefined,
    });
  }

  handleDoubleClickRowCombinacion(evt) {
    const id_combinacion = evt.currentTarget.getAttribute("data-id");
    this.setState({ id_combinacion, aside: "nueva-combinacion" });
  }

  handleSubmitCombinacionNuevo() {
    this.clearAside();
  }

  clearAside() {
    this.setState({
      aside: undefined,
    });
  }
}
