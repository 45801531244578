import React from 'react'

export default class Navbar extends React.Component{    
    render(){
        const menu_lv1 = this.getMenuLv1()
        const menu_lv2 = this.getMenuLv2()

        return (
            <nav>
                <div className='menu-lv-2 h-100 position-fixed'>
                    <ul className='list-unstyled'>
                        { menu_lv2 }
                    </ul>    
                </div>  
                <div className='menu-lv-1 position-fixed h-100 bg-white'>
                    <ul className='list-unstyled'>
                        { menu_lv1 }
                    </ul>
                </div>                          
            </nav>
        )
    }

    getMenuLv1(){
        const menu = []
        
        for(let i in this.props.modules){
            const module = this.props.modules[i]

            const active = i === this.props.active_module ? 'active' : ''

            const li = (
                <li key={'menu-lv1-'+i} className={`d-flex align-items-center justify-content-center flex-column position-relative ${active}`} data-id={i} onClick={this.props.onMenuItemClick} >
                    <div className="li-icon">
                        {module.icon}
                    </div>
                    <div className="li-title">
                        {module.title.toUpperCase()}
                    </div>
                </li>
            )
            menu.push(li)
        }

        return menu
    }

    getMenuLv2(){
        const module = this.props.modules[this.props.active_module]
        const menu = []

        for(let i in module.modules){
            const sub_module =  module.modules[i]
            const li = (
                <li key={'submenu-'+i} className='d-flex align-items-center' onClick={this.props.onSubMenuItemClick} data-id={i} >
                    { sub_module.icon }
                    { sub_module.title }
                </li>
            )
            menu.push(li)
        }
        
        return menu
    }
}