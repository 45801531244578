import React from "react";
import Aside from "./Aside";

let callbacks = [];

const listen = (callback) => {
  callbacks.push(callback);
};

const emit = () => {
  callbacks.forEach((callback) => {
    callback();
  });
};

class AsideContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      closeBtn:
        this.props.closeBtn === undefined || this.props.closeBtn === true
          ? true
          : false,
    };
    this.handleClickCerrar = this.handleClickCerrar.bind(this);
  }

  render() {
    return (
      <Aside
        title={this.props.title}
        children={this.props.children}
        open={this.state.open}
        onClickCerrar={this.handleClickCerrar}
        closeBtn={this.state.closeBtn}
      />
    );
  }

  handleClickCerrar() {
    this.setState(
      {
        open: false,
      },
      () => {
        setTimeout(() => {
          emit();
        }, 300);
      }
    );
  }
}

export { AsideContainer as default, listen };
