import React from "react";
import AsideContainer from "../../components/Aside/AsideContainer";
import CotizacionCheckContainer from "../../components/ContizacionCheck/CotizacionCheckContainer";
import ContratoContainer from "../../components/Contrato/ContratoContainer";
export default class Cotizaciones extends React.Component {
  render() {
    const content = this.getContent();
    return content;
  }

  getContent() {
    let title = "Añadir Cotización";

    if (this.props.aside && this.props.aside.props.cotizacion) {
      title = "#" + this.props.aside.props.cotizacion.id_cotizacion;
    }

    const aside = this.props.aside ? (
      <AsideContainer title={title}>{this.props.aside}</AsideContainer>
    ) : (
      ""
    );

    return (
      <div>
        {aside}
        {this.props.component}
        {this.props.showCheckList ? (
          <CotizacionCheckContainer
            cotizacion={this.props.cotizacion}
            onSubmitModal={this.props.onSubmitModal}
          />
        ) : (
          ""
        )}
        {this.props.showContrato ? (
          <ContratoContainer
            cotizacion={this.props.cotizacion}
            onHideModal={this.props.onHideContratoModal}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
