import React from 'react'
import FileChooser from './FileChooser'

function toBase64(file){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class FileChooserContainer extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            file: undefined
        }
        this.handleChange = this.handleChange.bind(this)
    }

    render() {
        return <FileChooser 
                    file={this.state.file}
                    onChange={this.handleChange} />
    }

    handleChange(evt){        
        switch (this.props.mode){
            case 'base64':
                this.sendBase64(evt)
                break
            default:
                break
        }
    }

    async sendBase64(evt){
        const file = evt.target.files[0]
        const base64 = await toBase64(file)
        this.props.onChange(base64)
        this.setState({ file })
    }

    
}