import * as Axios from "../libs/Axios/Axios";

const path = "/cotizacion";

export default class Cotizacion {
  static add(data) {
    return Axios.post(path, {
      ...data,
      id_usuario: localStorage.getItem("id_usuario"),
    });
  }

  static update(id_cotizacion, data) {
    return Axios.put(`${path}/${id_cotizacion}`, {
      ...data,
      id_usuario: localStorage.getItem("id_usuario"),
    });
  }

  static list() {
    return Axios.get(
      path + "?id_usuario=" + localStorage.getItem("id_usuario")
    );
  }

  static get(id_cotizacion) {
    return Axios.get(path + "?id_cotizacion=" + id_cotizacion);
  }

  static async aprobar(id_cotizacion) {
    return await Axios.post(`${path}/aprobar`, { id_cotizacion });
  }

  static async descarga(id_cotizacion) {
    return Axios.get(`${path}/descarga?id_cotizacion=${id_cotizacion}`);
  }

  static async getOpciones() {
    return [
      {
        id: 1,
        titulo:
          "Todos los gastos laborales tales como: Sueldos, Benefcios Sociales, Seguros de Vida Ley, CTS, Gratifcaciones.",
        tipo: 1,
      },
      { id: 2, titulo: "Supervision permanentee inopinada.", tipo: 1 },
      { id: 3, titulo: "Examen medico ocupacional.", tipo: 1 },
      { id: 4, titulo: "Prueba rapida covid Antigena mensual.", tipo: 1 },
      {
        id: 5,
        titulo: "SCTR para todo el personal asignado a la operacion.",
        tipo: 1,
      },
      {
        id: 6,
        titulo: "Uniformes y EPP para el personal asignado a la operacion.",
        tipo: 1,
      },
      {
        id: 7,
        titulo: "Equipo de comunicacion Smartphone. (sistema AVP Report)",
        tipo: 1,
      },
      { id: 8, titulo: "Centro de Control 24 horas / 365 días.", tipo: 1 },
      {
        id: 9,
        titulo: "Polizas de Responsabilidad Civil y Deshonestidad.",
        tipo: 1,
      },
      {
        id: 10,
        titulo:
          "Reuniones mensuales de evaluacion del sercicio y/o a demanda del cliente.",
        tipo: 1,
      },
      {
        id: 11,
        titulo: "Informe de Riesgo de seguridad al inicio del servicio (IPER).",
        tipo: 1,
      },
      {
        id: 12,
        titulo: "Escritorios ni mobiliario para el personal de AVP.",
        tipo: 2,
      },
      {
        id: 13,
        titulo:
          "Horas extras del personal fuera de los horarios establecidos en el Alcance de Servicio. En caso programen labores fuera de los horarios de trabajo, las horas extras seran re facturadas con el debido sustento y aprobacion del cliente.",
        tipo: 2,
      },
      {
        id: 14,
        titulo:
          "Gastos por homologacion o auditorías solicitadas por el cliente.",
        tipo: 2,
      },
      {
        id: 15,
        titulo:
          "Todo servicio adicional no contemplado en la presenta propuesta.",
        tipo: 2,
      },
      {
        id: 16,
        titulo: "Unidades de supervision movil ni combustible.",
        tipo: 2,
      },
      {
        id: 17,
        titulo: "Monitoreo al personal por médico ocupacional.",
        tipo: 1,
      },
      {
        id: 18,
        titulo: "Capacitación o charlas mensuales en seguridad.",
        tipo: 1,
      },
    ];
  }
}
