import React from 'react'
import {ReactComponent as ListIcon} from '../../images/List.svg'
import {ReactComponent as CardsIcon} from '../../images/Cards.svg'
import {ReactComponent as LupaIcon} from '../../images/Lupa.svg'

export default class MovimientosAlmacenList extends React.Component{
    render() {
        return (
            <main>
                <div className="header-block">
                    <div className="d-flex justify-content-between align-items-baseline mb-3">
                        <div className="title d-flex align-items-baseline">
                            <h3>Movimientos</h3>
                            <h6 className='ms-3 small fw-normal'>Inicio | Logistica | Movimientos</h6>
                        </div>
                        <button className='btn btn-primary btn-lg rounded-pill' onClick={this.props.onClickNuevo}>Nuevo Movimiento</button>
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline">
                        <div className='d-flex align-items-center'>
                            <div className='display-disposition'>
                                <button className="btn btn-sm"><ListIcon/></button>
                                <button className="btn btn-sm"><CardsIcon/></button>
                            </div>                            
                            <div className="ms-3 display-options">
                                <div className="btn-group btn-group-sm float-md-left me-1">
                                    <button className="btn btn-outline-primary btn-sm dropdown-toggle rounded-pill" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Activo</button>
                                    <ul className="dropdown-menu" x-placement="bottom-start">
                                    </ul>
                                </div>
                                <div className="input-icon-container search-sm calendar-sm d-inline-block float-md-left mr-1 align-top">
                                    <input className='form-control form-control-sm rounded-pill' type="text" placeholder='Buscar' value={this.props.searchWord} onChange={this.props.onChangeSearchWord}/>                        
                                    <LupaIcon className='input-icon input-icon-right' />
                                </div>
                            </div>           
                        </div>                         
                        <div>
                            <small className='small'>{this.props.movimientosVisibles.length} mov. {this.props.movimientosVisibles.length === 1 ? 'encontrado' : 'encontrados'}</small>
                        </div>                       
                    </div>
                </div>
                <div className="body-block">
                    <div className='table-component'>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Tipo</th>
                                    <th>Motivo</th>
                                    <th>Código OC</th>
                                    <th>Creado el</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getRows()}
                            </tbody>
                        </table>
                    </div>
                </div>                
            </main>
        )
    }

    getRows(){
        const rows = this.props.movimientosVisibles.map(mov => (
            <tr key={'mov' + mov.id_movimiento_almacen} data-id={mov.id_movimiento_almacen} onDoubleClick={this.props.onDoubleClickRow}>
                <td>{mov.id_movimiento_almacen}</td>
                <td>{mov.tipo === 1 ? 'INGRESO' : 'SALIDA'}</td>
                <td>{mov.motivo.toUpperCase()}</td>
                <td>{mov.id_orden_compra ? mov.id_orden_compra : ''}</td>
                <td>{mov.creado_el}</td>
            </tr>
        ))
        return rows
    }
}