import React from 'react'
import MovimientosAlmacenList from './MovimientosAlmacenList'
import MovimientoAlmacen from '../../services/MovimientoAlmacen'

export default class MovimientosAlmacenContainer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            movimientos: [],
            movimientosVisibles: [],
            searchWord: ''
        }

        this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this)

        this.obtenerMovimientos()
    }

    render() {
        return (
            <MovimientosAlmacenList
                searchWord={this.state.searchWord}
                onClickNuevo={this.props.onClickNuevo}
                movimientosVisibles={this.state.movimientosVisibles}
                onDoubleClickRow={this.props.onDoubleClickRow}
                onChangeSearchWord={this.handleChangeSearchWord}
                />
        )
    }

    handleChangeSearchWord(evt){
        this.setState({
            searchWord: evt.target.value
        }, () => this.buscar())
    }

    buscar(){
        const movimientosVisibles = this.state.movimientos.filter(elm => 
            elm.motivo.toUpperCase().includes(this.state.searchWord.toUpperCase()) ||
            ('SALIDA'.includes(this.state.searchWord.toUpperCase()) && elm.tipo === 2) ||
            ('INGRESO'.includes(this.state.searchWord.toUpperCase()) && elm.tipo === 1) ||
            Number(this.state.searchWord.toUpperCase()) === elm.id_orden_compra ||
            elm.creado_el.toUpperCase().includes(this.state.searchWord.toUpperCase())
        )
        this.setState({ movimientosVisibles })
    }

    async obtenerMovimientos(){
        const data = await MovimientoAlmacen.list()
        this.setState({
            movimientos: data.results,
            movimientosVisibles: data.results
        })
    }
}