import React from "react";
import ModalContainer from "../Modal/ModalContainer";
import Misc from "../../helpers/Misc";

export default class Contrato extends React.Component {
  render() {
    const options = [
      <option key="contacto-0" value=""></option>,
      ...this.props.contactos.map((elm, idx) => {
        return (
          <option key={`contacto-${idx + 1}`} value={elm.id_persona_contacto}>
            {elm.nombre}
          </option>
        );
      }),
    ];

    return (
      <ModalContainer
        size="md"
        onSubmitModal={this.props.onSubmitModal}
        onHideModal={this.props.onHideModal}
        title="Generar Contrato"
      >
        <div className="form-group mb-2">
          <label>Tipo de Contrato</label>
          <select
            className="form-select"
            value={this.props.contrato.tipo_contrato}
            onChange={this.props.onChangeTipoContrato}
          >
            <option value="1">Nuevo</option>
            <option value="2">Adenda</option>
          </select>
        </div>
        <div className="form-group mb-2">
          <label>Contacto</label>
          <select
            className="form-select"
            value={this.props.contrato.id_contacto || ""}
            onChange={this.props.onChangeContacto}
          >
            {options}
          </select>
        </div>
        <div className="form-group mb-2">
          <label>N° Partida</label>
          <input
            type="text"
            className="form-control datepicker"
            value={this.props.contrato.partida || ""}
            onChange={this.props.onChangePartida}
          />
        </div>
        <div className="form-group mb-2">
          <label>Fianza</label>
          <input
            type="number"
            className="form-control datepicker"
            value={this.props.contrato.fianza || ""}
            onChange={this.props.onChangeFianza}
          />
        </div>
        <div
          className={
            "form-group mb-2 " +
            (this.props.contrato.tipo_contrato !== "2" ? "d-none" : "")
          }
        >
          <label>Fecha de Inicio - Contrato Vigente</label>
          <input
            type="date"
            className="form-control datepicker"
            placeholder="dd-mm-aaaa"
            value={this.props.contrato.fecha_inicio_actual || ""}
            onChange={this.handleChangeFechaInicioActual}
          />
        </div>
        <div
          className={
            "form-group mb-2 " +
            (this.props.contrato.tipo_contrato !== "2" ? "d-none" : "")
          }
        >
          <label>Fecha de Fin - Contrato Vigente</label>
          <input
            type="date"
            className="form-control datepicker"
            placeholder="dd-mm-aaaa"
            value={this.props.contrato.fecha_fin_actual || ""}
            onChange={this.handleChangeFechaFinActual}
          />
        </div>
        <div className="form-group mb-2">
          <label>Fecha de Inicio</label>
          <input
            type="date"
            className="form-control datepicker"
            placeholder="dd-mm-aaaa"
            min={Misc.getDate()}
            value={this.props.contrato.fecha_inicio || ""}
            onChange={this.props.onChangeFechaInicio}
          />
        </div>
        <div className="form-group mb-2">
          <label>Fecha de Fin</label>
          <input
            type="date"
            className="form-control datepicker"
            placeholder="dd-mm-aaaa"
            min={Misc.getDate()}
            value={this.props.contrato.fecha_fin || ""}
            onChange={this.handleChangeFechaFin}
          />
        </div>
      </ModalContainer>
    );
  }
}
