import React from "react";
import { ReactComponent as OptionsIcon } from "../../images/Options.svg";

export default class DropdownView extends React.Component {
  render() {
    return (
      <div className="dropdown">
        <button
          className="btn"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <OptionsIcon />
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {this.getList()}
        </ul>
      </div>
    );
  }

  getList() {
    return this.props.list.map((elm, idx) => (
      <li
        key={"dd-" + idx + "-" + Date.now()}
        className={"dropdown-item"}
        onClick={() => {
          console.log(elm);
          elm.action(elm.value);
        }}
      >
        {elm.text}
      </li>
    ));
  }
}
