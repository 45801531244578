import React from 'react'
import OrdenesCompraListContainer from '../../components/OrdenesCompraList/OrdenesCompraListContainer'
import OrdenesCompraDetallecontainer from '../../components/OrdenesCompraDetalle/OrdenesCompraDetalleContainer'
import AsideContainer from '../../components/Aside/AsideContainer'
import CombinacionAniadirContainer from '../../components/CombinacionAniadir/CombinacionAniadirContainer'

export default class OrdenesCompra extends React.Component{

    render(){
        const content = this.getContent()
        return content
    }

    getContent(){
        let content = '';

        switch (this.props.modo){
            case 1: 
                content = <OrdenesCompraListContainer                             
                            onClickNuevo={this.props.onClickNuevo}
                            onDoubleClickListItem={this.props.onDoubleClickListItem} />
                break
            case 2: 
                const aside = !this.props.ordenCompra ? (
                    <AsideContainer title="Añadir Item" >
                        <CombinacionAniadirContainer onSubmit={this.props.onSubmitAniadirItem} precio={true} />
                    </AsideContainer>
                ) : ''
                
                content = (
                    <div>                        
                        {aside}
                        <OrdenesCompraDetallecontainer
                            ordenCompra={this.props.ordenCompra}
                            items={this.props.items}
                            onClickDeleteRow={this.props.onClickDeleteRow}
                            onSubmit={this.props.onSubmitOC}
                            onChangeProveedor={this.props.onChangeProveedor} />
                    </div>                    
                )
                break
            default:
                content = ''
                break
        }

        return content         
    }
}