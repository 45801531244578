import React from "react";

export default class ItemNuevoView extends React.Component {
  render() {
    return (
      <div className="ps-4 pe-4 pt-3">
        <div className="form-group mb-2 position-relative">
          <label>Descripción</label>
          <input
            type="text"
            className="form-control"
            value={this.props.item.descripcion || ""}
            onChange={this.props.onChangeDescripcion}
          />
        </div>
        <div className="aside-footer position-fixed end-0 bottom-0 p-3">
          <button
            className="btn btn-primary rounded-pill"
            onClick={this.props.onSubmit}
          >
            Guardar
          </button>
        </div>
      </div>
    );
  }
}
