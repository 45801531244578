import * as Axios from "../libs/Axios/Axios";

const path = "/empresa/cuenta";

export default class Cuenta {
  static list() {
    return Axios.get(path);
  }

  static serviciosList(id_cuenta) {
    const params = [{ key: "id_cuenta", value: id_cuenta }];
    return Axios.get(path + "/servicio", params);
  }

  static serviciosGet(id_servicio) {
    const params = [{ key: "id_servicio", value: id_servicio }];
    return Axios.get(path + "/servicio", params);
  }
}
