import React from "react";
import AsideContainer from "../Aside/AsideContainer";
import CombinacionAniadirContainer from "../CombinacionAniadir/CombinacionAniadirContainer";
import ServicioAniadirContainer from "../ServicioAniadir/ServicioAniadirContainer";
import SelectSearchContainer from "../SelectSearch/SelectSearchContainer";
import { ReactComponent as CerrarIcon } from "../../images/Cerrar.svg";

export default class CotizacionDetalle extends React.Component {
  render() {
    return (
      <div>
        <AsideContainer title="Añadir" closeBtn={false}>
          <div className="form-group mb-2 ps-4 pe-4 mt-3">
            <SelectSearchContainer
              value={this.props.tipoAside}
              options={this.props.tiposAside}
              id="tipoAside"
              valueKey="id"
              displayKey="value"
              placeholder=""
              onChange={this.props.onChangeTipoAside}
            />
          </div>
          {this.props.tipoAside === 1 ? (
            <CombinacionAniadirContainer
              onSubmit={this.props.onSubmitCombinacionAniadir}
            />
          ) : (
            <ServicioAniadirContainer
              onSubmit={this.props.onSubmitServicioAniadir}
            />
          )}
        </AsideContainer>
        <main>
          <div className="header-block">
            <div className="d-flex justify-content-between align-items-baseline mb-3">
              <div className="title d-flex align-items-baseline">
                <h3>{this.props.title}</h3>
                <h6 className="ms-3 small fw-normal">
                  {this.props.empresa ? this.props.empresa.razon_social : ""}
                </h6>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-baseline">
              <div>
                <small className="small">
                  {this.props.items.length}
                  {this.props.items.length === 1 ? " elemento" : " elementos"}
                </small>
              </div>
            </div>
          </div>
          <div className="body-block">
            <div className="table-component">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>Ubicación</th>
                    <th>Cantidad</th>
                    <th>Producto</th>
                    <th>Precio U.</th>
                    <th>Importe</th>
                  </tr>
                </thead>
                <tbody>{this.getRows()}</tbody>
              </table>
            </div>
          </div>
          <div className="footer-block position-absolute bottom-0 start-0 mb-4 w-100">
            {this.getFooter()}
          </div>
        </main>
      </div>
    );
  }

  getRows() {
    return this.props.items.map((item, idx) => (
      <tr key={"cot-tbl-" + idx}>
        <td>{item.descripcion_ubicacion || " - "}</td>
        <td>{item.cantidad}</td>
        <td>
          {item.abreviatura
            ? item.abreviatura + (item.armado ? " (armado)" : "")
            : item.combinacion}
        </td>
        <td>{item.precio}</td>
        <td>{item.importe}</td>
        <td className="p-0 position-relative options">
          <CerrarIcon
            className={
              "position-absolute left-0 top-0" +
              (this.props.ordenCompra ? " d-none" : "")
            }
            type="button"
            data-id={item.id_combinacion}
            onClick={() => {
              this.props.onClickDeleteRow(idx);
            }}
          />
        </td>
      </tr>
    ));
  }

  getFooter() {
    const subtotal = this.props.subtotal;
    const igv = this.props.subtotal * 0.18;
    const total = subtotal + igv;

    return (
      <div className="bg-white px-3 py-2 d-flex justify-content-between align-items-end">
        <div className="d-flex">
          <div className="form-group me-2">
            <label>SUB TOTAL</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              disabled
              value={"S/ " + subtotal.toFixed(2)}
            />
          </div>
          <div className="form-group me-2">
            <label>I.G.V.</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              disabled
              value={"S/ " + igv.toFixed(2)}
            />
          </div>
          <div className="form-group me-2">
            <label>TOTAL</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              disabled
              value={"S/ " + total.toFixed(2)}
            />
          </div>
        </div>
        <div>
          <button
            className="btn btn-primary rounded-pill text-nowrap"
            onClick={this.props.onSubmitCotizacion}
            disabled={this.props.ordenCompra ? true : false}
          >
            {this.props.id_cotizacion ? "ACTUALIZAR" : "COTIZAR"}
          </button>
        </div>
      </div>
    );
  }
}
