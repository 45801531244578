import React from "react";
import Empresas from "./Empresas";
import EmpresaListContainer from "../../components/EmpresaList/EmpresaListContainer";
import EmpresaNuevoContainer from "../../components/EmpresaNuevo/EmpresaNuevoContainer";

export default class EmpresasContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickNuevo = this.handleClickNuevo.bind(this);
    this.state = {
      component: <EmpresaListContainer onClickNuevo={this.handleClickNuevo} />,
      aside: undefined,
    };
  }

  render() {
    return (
      <Empresas aside={this.state.aside} component={this.state.component} />
    );
  }

  handleClickNuevo() {
    this.setState({
      aside: (
        <div class="tab-pane">
          <EmpresaNuevoContainer onSubmit={this.props.onSubmitAniadirItem} />
        </div>
      ),
    });
  }
}
