import React from 'react'
import Combinacion from '../../services/Combinacion'
import StockList from './StockList'

export default class StockListContainer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            searchWord: '',
            combinaciones: [],
            combinacionesVisibles: []
        }

        this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this)
    }    
    
    render(){
        return <StockList
                    combinaciones={this.state.combinacionesVisibles}
                    searchWord={this.state.searchWord}
                    onChangeSearchWord={this.handleChangeSearchWord} />
    }

    componentDidMount(){
        this.listarCombinaciones()
    }

    handleChangeSearchWord(evt){
        this.setState({
            searchWord: evt.target.value
        }, () => this.buscarCombinaciones())
    }

    async listarCombinaciones(){
        const data = await Combinacion.list()
        const combinaciones = data.results
        this.setState({
            combinaciones
        }, () => this.buscarCombinaciones())
    }

    buscarCombinaciones(){
        let combinacionesVisibles = []

        if(this.state.searchWord.trim() === ''){
            combinacionesVisibles = this.state.combinaciones
        }else{
            const word = this.state.searchWord.trim().toUpperCase()
            combinacionesVisibles = this.state.combinaciones.filter(comb => 
                    String(comb.id_combinacion) === word ||
                    comb.combinacion.toUpperCase().includes(word)
                )
        }

        this.setState({
            combinacionesVisibles
        })
    }
}