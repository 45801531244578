import React from 'react'
import OrdenesCompraDetalle from './OrdenesCompraDetalle'
import { addNotification } from '../Notifications/Notifications'
import Empresa from '../../services/Empresa'

export default class OrdenesCompraDetallecontainer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            proveedores: [],
            title: 'Nueva Orden de Compra'
        }
        this.handleClickRow = this.handleClickRow.bind(this)
        this.getSubTotal = this.getSubTotal.bind(this)
    }

    componentDidMount() {
        this.obtenerProveedores()
    }

    render(){
        return <OrdenesCompraDetalle 
                    ordenCompra={this.props.ordenCompra} 
                    items={this.props.items}
                    proveedores={this.state.proveedores}
                    title={this.state.title}
                    onClickRow={this.handleClickRow}
                    onClickDeleteRow={this.props.onClickDeleteRow} 
                    subtotal={this.getSubTotal()}
                    onSubmit={this.props.onSubmit}
                    onChangeProveedor={this.props.onChangeProveedor} />
    }    

    handleClickRow(evt){
        // const id_combinacion = evt.currentTarget.getAttribute('data-id')
    }

    async obtenerProveedores(){
        try{
            const data = await Empresa.list()
            if(data.complete){
                this.setState({ proveedores: data.results})
            }else{
                addNotification(data.err, 'bg-warning')    
            }
        }catch(err){
            addNotification('Error al obtener proveedores', 'bg-danger')
        }
    }

    getSubTotal(){
        const subtotal = this.props.items.reduce((A, B) => A + (B.cantidad * B.precio), 0)
        return subtotal
    }
}