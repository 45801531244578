import React from "react";
import { ReactComponent as ListIcon } from "../../images/List.svg";
import { ReactComponent as CardsIcon } from "../../images/Cards.svg";
import { ReactComponent as LupaIcon } from "../../images/Lupa.svg";

export default class CuentaDetalle extends React.Component {
  render() {
    return (
      <main>
        <div className="header-block">
          <div className="d-flex justify-content-between align-items-baseline mb-3">
            <div className="title d-flex align-items-baseline">
              <h3>Servicios</h3>
              <h6 className="ms-3 small fw-normal">Inicio | CRM | Cuentas</h6>
            </div>
            <button
              className="btn btn-primary btn-lg rounded-pill"
              onClick={this.props.onClickNuevo}
            >
              Nuevo Servicio
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-baseline">
            <div className="d-flex align-items-center">
              <div className="display-disposition">
                <button className="btn btn-sm">
                  <ListIcon />
                </button>
                <button className="btn btn-sm">
                  <CardsIcon />
                </button>
              </div>
              <div className="ms-3 display-options">
                <div className="btn-group btn-group-sm float-md-left me-1">
                  <button
                    className="btn btn-outline-primary btn-sm dropdown-toggle rounded-pill"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Activo
                  </button>
                  <ul className="dropdown-menu" x-placement="bottom-start"></ul>
                </div>
                <div className="input-icon-container search-sm calendar-sm d-inline-block float-md-left mr-1 align-top">
                  <input
                    className="form-control form-control-sm rounded-pill"
                    type="text"
                    placeholder="Buscar"
                    value={this.props.searchWord}
                    onChange={this.props.onChangeSearchWord}
                  />
                  <LupaIcon className="input-icon input-icon-right" />
                </div>
              </div>
            </div>
            <div>
              <small className="small">
                {this.props.servicios.length}
                {this.props.servicios.length === 1
                  ? " servicio encontrado"
                  : " servicios encontrados"}
              </small>
            </div>
          </div>
        </div>
        <div className="body-block">
          <div className="table-component">
            <table className="w-100">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Fecha Inicio</th>
                  <th>Fecha Fin</th>
                  {/* <th>Stock</th>
                  <th>Mínimo</th>
                  <th>Auto</th> */}
                </tr>
              </thead>
              <tbody>{this.getRows()}</tbody>
            </table>
          </div>
        </div>
      </main>
    );
  }

  getRows() {
    const rows = this.props.servicios.map((servicio) => (
      <tr
        key={"servicio" + servicio.id_servicio}
        data-id={servicio.id_servicio}
        onDoubleClick={this.props.onDoubleClickRow}
      >
        <td>{servicio.id_servicio}</td>
        <td>{servicio.fecha_inicio}</td>
        <td>{servicio.fecha_fin}</td>
        {/* <td>{comb.stock}</td>
        <td>{comb.stock_min}</td>
        <td>{comb.stock_auto}</td> */}
      </tr>
    ));
    return rows;
  }
}
