import React from "react";
import Cotizaciones from "./Cotizaciones";
import CotizacionListContainer from "../../components/CotizacionList/CotizacionListContainer";
import CotizacionNuevoContainer from "../../components/CotizacionNuevo/CotizacionNuevoContainer";
import CotizacionDetalleContainer from "../../components/CotizacionDetalle/CotizacionDetalleContainer.js";
import { listen } from "../../components/Aside/AsideContainer";

export default class CotizacionesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickNuevo = this.handleClickNuevo.bind(this);
    this.cerrarNuevo = this.cerrarNuevo.bind(this);
    this.handleClickCerrarNuevo = this.handleClickCerrarNuevo.bind(this);
    this.handleClickCotizar = this.handleClickCotizar.bind(this);
    this.handleSubmitCotizacionDetalle =
      this.handleSubmitCotizacionDetalle.bind(this);
    this.handleDoubleClickCotizacionListItem =
      this.handleDoubleClickCotizacionListItem.bind(this);
    this.handleClickAprobar = this.handleClickAprobar.bind(this);
    this.handleClickDescargar = this.handleClickDescargar.bind(this);
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
    this.handleClickContrato = this.handleClickContrato.bind(this);
    this.handleHideContratoModal = this.handleHideContratoModal.bind(this);
    this.handleClickMostrarCotizacion =
      this.handleClickMostrarCotizacion.bind(this);
    this.state = {
      component: (
        <CotizacionListContainer
          onClickNuevo={this.handleClickNuevo}
          onDoubleClickCotizacionListItem={
            this.handleDoubleClickCotizacionListItem
          }
          onClickMostrarCotizacion={this.handleClickMostrarCotizacion}
        />
      ),
      cotizacion: undefined,
      showCheckList: false,
      showContrato: false,
      aside: undefined,
      id_cotizacion: undefined,
    };
    listen(this.cerrarNuevo);
  }

  render() {
    return (
      <Cotizaciones
        aside={this.state.aside}
        component={this.state.component}
        showCheckList={this.state.showCheckList}
        showContrato={this.state.showContrato}
        cotizacion={this.state.cotizacion}
        onSubmitModal={this.handleSubmitModal}
        onHideContratoModal={this.handleHideContratoModal}
      />
    );
  }

  handleClickAprobar() {
    this.setState({
      component: (
        <CotizacionListContainer
          onClickNuevo={this.handleClickNuevo}
          onDoubleClickCotizacionListItem={
            this.handleDoubleClickCotizacionListItem
          }
          onClickMostrarCotizacion={this.handleClickMostrarCotizacion}
          time={Date.now()}
        />
      ),
    });
  }

  handleClickNuevo() {
    this.setState({
      aside: (
        <CotizacionNuevoContainer
          onSubmit={this.props.onSubmitAniadirItem}
          onClickCerrar={this.handleClickCerrarNuevo}
          onClickCotizar={this.handleClickCotizar}
        />
      ),
    });
  }

  handleClickCerrarNuevo() {
    this.cerrarNuevo();
  }

  handleClickCotizar({ id_empresa, id_contacto }) {
    this.setState({
      aside: undefined,

      component: (
        <CotizacionDetalleContainer
          id_empresa={id_empresa}
          id_contacto={id_contacto}
          id_cotizacion={this.state.id_cotizacion}
          onSubmit={this.handleSubmitCotizacionDetalle}
        />
      ),
    });
  }

  handleSubmitCotizacionDetalle() {
    console.log("handleSubmitCotizacionDetalle");
    this.setState({
      component: (
        <CotizacionListContainer
          onClickNuevo={this.handleClickNuevo}
          onDoubleClickCotizacionListItem={
            this.handleDoubleClickCotizacionListItem
          }
          onClickMostrarCotizacion={this.handleClickMostrarCotizacion}
        />
      ),
      cotizacion: undefined,
      showCheckList: false,
      showContrato: false,
      aside: undefined,
    });
  }

  handleDoubleClickCotizacionListItem(cotizacion) {
    this.setState({
      cotizacion,
      aside: (
        <CotizacionNuevoContainer
          cotizacion={cotizacion}
          onSubmit={this.props.onSubmitAniadirItem}
          onClickCerrar={this.handleClickCerrarNuevo}
          onClickCotizar={this.handleClickCotizar}
          onClickAprobar={this.handleClickAprobar}
          onClickDescargar={this.handleClickDescargar}
          onClickContrato={this.handleClickContrato}
        />
      ),
    });
  }

  handleClickDescargar() {
    this.setState({
      showCheckList: true,
    });
  }

  handleSubmitModal() {
    this.setState({ showCheckList: false });
  }

  handleClickContrato() {
    this.setState({ showContrato: true });
  }

  handleHideContratoModal() {
    this.setState({ showContrato: false });
  }

  handleClickMostrarCotizacion(id_cotizacion) {
    this.setState({
      aside: undefined,
      component: (
        <CotizacionDetalleContainer
          id_cotizacion={id_cotizacion}
          onSubmit={this.handleSubmitCotizacionDetalle}
        />
      ),
    });
  }

  cerrarNuevo() {
    this.setState({
      aside: undefined,
    });
  }
}
