import React from "react";
import Cuentas from "./Cuentas";
import CuentaListContainer from "../../components/CuentaList/CuentaListContainer";
import CuentaDetalleContainer from "../../components/CuentaDetalle/CuentaDetalleContainer";
import ServicioDetalleContainer from "../../components/ServicioDetalle/ServicioDetalleContainer";

export default class CuentasContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickNuevo = this.handleClickNuevo.bind(this);
    this.handleSelectCuenta = this.handleSelectCuenta.bind(this);
    this.handleSelectServicio = this.handleSelectServicio.bind(this);
    this.state = {
      component: (
        <CuentaListContainer
          onClickNuevo={this.handleClickNuevo}
          onSelectCuenta={this.handleSelectCuenta}
        />
      ),
      aside: undefined,
    };
  }

  render() {
    return (
      <Cuentas aside={this.state.aside} component={this.state.component} />
    );
  }

  handleSelectCuenta(id_cuenta) {
    this.setState({
      component: (
        <CuentaDetalleContainer
          id_cuenta={id_cuenta}
          onSelectServicio={this.handleSelectServicio}
        />
      ),
    });
  }

  handleSelectServicio(id_servicio) {
    this.setState({
      component: <ServicioDetalleContainer id_servicio={id_servicio} />,
    });
  }

  handleClickNuevo() {
    // this.setState({
    //   aside: (
    //     <EmpresaNuevoContainer onSubmit={this.props.onSubmitAniadirItem} />
    //   ),
    // });
  }
}
