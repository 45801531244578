import React from "react";

export default class TabPane extends React.Component {
  render() {
    return (
      <div className="h-100-force">
        <ul
          className="nav nav-tabs d-flex justify-content-between flex-nowrap"
          role="tablist"
        >
          {this.getNav()}
        </ul>
        <div className="tab-content">{this.getContent()}</div>
      </div>
    );
  }

  getContent() {
    return this.props.items.map((itm, idx) => {
      return (
        <div
          className={
            "tab-pane fade h-100-force" +
            (itm.id === this.props.active.id ? " active show" : "")
          }
          id={itm.id}
          role="tabpanel"
          key={"tab-itm-content" + itm.id}
        >
          <div className="content-frame">
            {
              itm.content //onClicksiguiente
            }
          </div>
        </div>
      );
    });
  }

  getNav() {
    return this.props.items.map((itm, idx) => (
      <li className="nav-item" key={"menu-itm-tab-" + itm.id}>
        <button
          className={
            "nav-link text-center" +
            (itm.id === this.props.active.id ? " active" : "")
          }
          data-target={itm.tab}
          type="button"
          onClick={() => {
            this.props.onClickItem(itm);
          }}
        >
          Paso {idx + 1}
          <br />
          {itm.title}
        </button>
      </li>
    ));
  }
}
