import React from "react";
import { ReactComponent as CerrarIcon } from "../../images/Cerrar.svg";

export default class SelectSearch extends React.Component {
  render() {
    const options = this.getOptions();

    return (
      <div className="SelectSearchComponent dropdown">
        <input
          className="form-control"
          type="text"
          id={"dropdown-" + this.props.id}
          data-bs-toggle="dropdown"
          placeholder={this.props.placeholder}
          autoComplete="nocompletar"
          value={this.props.displayValue}
          onChange={this.props.onChangeInput}
        />
        <CerrarIcon
          className="position-absolute top-0 end-0 mt-1 me-1 invisible"
          width="15px"
          type="button"
          onClick={this.props.onClickClean}
        />
        <ul
          className="dropdown-menu"
          style={{
            maxHeight: "210px",
            overflow: "auto",
          }}
        >
          {options}
        </ul>
      </div>
    );
  }

  getOptions() {
    if (this.props.options) {
      return this.props.options.map((elm) => (
        <li
          className="dropdown-item"
          data-value={elm[this.props.valueKey]}
          key={`dropdown-${this.props.id}-opt-${elm[this.props.valueKey]}`}
          data-id={elm[this.props.valueKey]}
          onClick={this.props.onClickOption}
        >
          {elm[this.props.displayKey]}
        </li>
      ));
    }
    return "";
  }
}
