import React from "react";
import SelectSearchContainer from "../SelectSearch/SelectSearchContainer";

export default class ContactoNuevo extends React.Component {
  render() {
    return (
      <div>
        <div className="content-frame">
          <div className="form-group mb-2">
            <label>Relación</label>
            <SelectSearchContainer
              value={this.props.contacto.id_persona_relacion}
              options={this.props.relaciones}
              id="relaciones"
              valueKey="id_persona_relacion"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeRelacion}
            />
          </div>
          <div className="form-group mb-2">
            <label>Tipo de documento</label>
            <SelectSearchContainer
              value={this.props.contacto.id_tipo_documento_identidad}
              options={this.props.tipos_documento_identidad}
              id="tipo_documento_identidad"
              valueKey="id_tipo_documento_identidad"
              displayKey="abreviatura"
              placeholder=""
              onChange={this.props.onChangeTipoDocumentoIdentidad}
            />
          </div>
          <div className="form-group mb-2">
            <label>Número de documento</label>
            <input
              className="form-control"
              placeholder=""
              onChange={this.props.onChangeDocumento}
              value={this.props.contacto.documento || ""}
            />
          </div>
          <div className="form-group mb-2">
            <label>Nombres</label>
            <input
              className="form-control"
              placeholder=""
              onChange={this.props.onChangeNombres}
              value={this.props.contacto.nombres || ""}
            />
          </div>
          <div className="form-group mb-2">
            <label>Apellido paterno</label>
            <input
              className="form-control"
              placeholder=""
              onChange={this.props.onChangeApPaterno}
              value={this.props.contacto.ap_paterno || ""}
            />
          </div>
          <div className="form-group mb-2">
            <label>Apellido materno</label>
            <input
              className="form-control"
              placeholder=""
              onChange={this.props.onChangeApMaterno}
              value={this.props.contacto.ap_materno || ""}
            />
          </div>
          <div className="form-group mb-2">
            <label>Número de Teléfono</label>
            <input
              className="form-control"
              placeholder=""
              onChange={this.props.onChangeTelefono}
              value={this.props.contacto.telefono || ""}
            />
          </div>
          <div className="form-group mb-2">
            <label>Correo Electrónico</label>
            <input
              className="form-control"
              placeholder=""
              onChange={this.props.onChangeEmail}
              value={this.props.contacto.email || ""}
            />
          </div>
          <div className="form-group mb-2">
            <label>Fecha de nacimiento</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              onChange={this.props.onChangeFechaNacimiento}
              value={this.props.contacto.fecha_nacimiento || ""}
            />
          </div>
          <div className="form-group mb-2">
            <label>Departamento - Domicilio</label>
            <SelectSearchContainer
              value={this.props.contacto.id_departamento_domicilio}
              options={this.props.departamentos}
              id="departamento"
              valueKey="id_departamento"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeDepartamento}
            />
          </div>
          <div className="form-group mb-2">
            <label>Provincia - Domicilio</label>
            <SelectSearchContainer
              value={this.props.contacto.id_provincia_domicilio}
              options={this.props.provincias}
              id="provincia"
              valueKey="id_provincia"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeProvincia}
            />
          </div>
          <div className="form-group mb-2">
            <label>Distrito - Domicilio</label>
            <SelectSearchContainer
              value={this.props.contacto.id_distrito_domicilio}
              options={this.props.distritos}
              id="distrito"
              valueKey="id_distrito"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeDistrito}
            />
          </div>
          <div className="form-group mb-2">
            <label>Tipo de Vía - Domicilio</label>
            <SelectSearchContainer
              value={this.props.contacto.id_tipo_via_domicilio}
              options={this.props.tipos_via}
              id="tipo_via"
              valueKey="id_tipo_via"
              displayKey="descripcion"
              placeholder=""
              onChange={this.props.onChangeTipoVia}
            />
          </div>
          <div className="form-group mb-2">
            <label>Vía - Domicilio</label>
            <input
              className="form-control"
              type="text"
              value={this.props.contacto.via_domicilio || ""}
              onChange={this.props.onChangeVia}
            />
          </div>
          <div className="form-group mb-2">
            <label>Numeración de Vía - Domicilio</label>
            <input
              className="form-control"
              type="text"
              value={this.props.contacto.numeracion_via_domicilio || ""}
              onChange={this.props.onChangeNumeracionVia}
            />
          </div>
          <div className="form-group mb-2">
            <label>Dirección Domicilio</label>
            <input
              type="text"
              value={this.props.contacto.direccion_domicilio || ""}
              onChange={this.props.onChangeDireccionDomicilio}
              className="form-control datepicker"
            />
          </div>
        </div>
        <div className="aside-footer position-fixed end-0 bottom-0 p-3">
          <button
            className="btn btn-outline-primary rounded-pill me-2"
            onClick={this.props.onClickAtras}
          >
            Atras
          </button>
          <button
            className="btn btn-primary rounded-pill"
            onClick={this.props.onClickGuardar}
          >
            Guardar
          </button>
        </div>
      </div>
    );
  }
}
