import React from 'react'
import {ReactComponent as ListIcon} from '../../images/List.svg'
import {ReactComponent as CardsIcon} from '../../images/Cards.svg'
import {ReactComponent as LupaIcon} from '../../images/Lupa.svg'

export default class OrdenesCompraList extends React.Component{
    render() {
        return (
            <main>
                <div className="header-block">
                    <div className="d-flex justify-content-between align-items-baseline mb-3">
                        <div className="title d-flex align-items-baseline">
                            <h3>Ordenes de Compra</h3>
                            <h6 className='ms-3 small fw-normal'>Inicio | Logistica | Ordenes de Compra</h6>
                        </div>
                        <button className='btn btn-primary btn-lg rounded-pill' onClick={this.props.onClickNuevo}>Nueva Orden</button>
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline">
                        <div className='d-flex align-items-center'>
                            <div className='display-disposition'>
                                <button className="btn btn-sm"><ListIcon/></button>
                                <button className="btn btn-sm"><CardsIcon/></button>
                            </div>                            
                            <div className="ms-3 display-options">
                                <div className="btn-group btn-group-sm float-md-left me-1">
                                    <button className="btn btn-outline-primary btn-sm dropdown-toggle rounded-pill" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Activo</button>
                                    <ul className="dropdown-menu" x-placement="bottom-start">
                                        <li className="dropdown-item">Activo</li> 
                                        <li className="dropdown-item">Cancelado</li>
                                        <li className="dropdown-item">Cotización</li>
                                        <li className="dropdown-item">Firma</li>
                                        <li className="dropdown-item">Cerrado</li>
                                    </ul>
                                </div>
                                <div className="input-icon-container search-sm calendar-sm d-inline-block float-md-left mr-1 align-top">
                                    <input className='form-control form-control-sm rounded-pill' type="text" placeholder='Buscar' value={this.props.searchWord} onChange={this.props.onChangeSearchWord}/>                        
                                    <LupaIcon className='input-icon input-icon-right' />
                                </div>
                            </div>           
                        </div>                         
                        <div>
                            <small className='small'>{this.props.items.length} {this.props.items.length === 1 ? ' orden encontrada' : ' ordenes encontradas'}</small>
                        </div>                       
                    </div>
                </div>
                <div className="body-block">
                    {this.getTable()}
                </div>                
            </main>
        )
    }

    getTable(){
        if(this.props.items.length > 0){
            return (
                <div className='table-component'>
                    <table className="w-100">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Proveedor</th>
                                <th>RUC</th>
                                <th>Creado el</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getRows()}
                        </tbody>
                    </table>
                </div>                
            )
        }else{
            return ''   
        }
    }

    getRows(){
        return this.props.items.map((item) =>                 
            <tr className="position-relative" key={'itm-'+item.id_orden_compra} data-id={item.id_orden_compra} onDoubleClick={this.props.onDoubleClick}>
                <td>{item.id_orden_compra}</td>
                <td>{item.razon_social}</td>
                <td>{item.ruc}</td>
                <td>{item.creado_el}</td>
            </tr>
        )
    }
}