
import * as Axios from '../libs/Axios/Axios'

const path = '/movimiento-almacen/motivo'

export default class MovimientoAlmacenMotivo {

    static listByTipo(tipo){
        const params = [{key: 'tipo', value: tipo}]
        return Axios.get(path, params)
    }    

}