import React from "react";
import CombinacionListView from "./CombinacionListView";
import Producto from "../../services/Producto";
import { subscribe } from "../CombinacionNuevo/CombinacionNuevo";

export default class CombinacionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: "",
      combinaciones: [],
      combinacionesVisibles: [],
      item: "",
    };
    this.getCombinaciones = this.getCombinaciones.bind(this);
    this.getCombinaciones();
    subscribe(this.getCombinaciones);
  }

  render() {
    return (
      <CombinacionListView
        combinaciones={this.state.combinacionesVisibles}
        onChangeSearchWord={this.handleChangeSearchWord}
        onClickNuevo={this.props.onClickNuevo}
        onDoubleClickRow={this.props.onDoubleClickRow}
        item={this.state.item}
      />
    );
  }

  async getCombinaciones() {
    const { results } = await Producto.getOne(this.props.idItem);
    this.setState(
      {
        item: results[0].item,
        combinaciones: results[0].combinacion === null ? [] : results,
      },
      () => {
        this.buscarCombinaciones();
      }
    );
  }

  buscarCombinaciones() {
    let combinacionesVisibles = [];

    if (this.state.searchWord.trim() === "") {
      combinacionesVisibles = this.state.combinaciones;
    } else {
      const word = this.state.searchWord.trim().toUpperCase();
      combinacionesVisibles = this.state.combinaciones.filter(
        (comb) =>
          String(comb.id_combinacion) === word ||
          comb.combinacion.toUpperCase().includes(word)
      );
    }

    this.setState({
      combinacionesVisibles,
    });
  }
}
