import * as Axios from "../libs/Axios/Axios";

const path = "/ubicacion";

export default class Ubicacion {
  static departamentosList() {
    return Axios.get(path + "/departamento");
  }

  static provinciasList(id_departamento) {
    const params = [{ key: "id_departamento", value: id_departamento }];
    return Axios.get(path + "/provincia", params);
  }

  static distritosList(id_provincia) {
    const params = [{ key: "id_provincia", value: id_provincia }];
    return Axios.get(path + "/distrito", params);
  }

  static tiposViaList() {
    return Axios.get(path + "/tipo-via");
  }
}
