import React from 'react'

import StockListContainer from '../../components/StockList/StockListContainer'

export default class Stock extends React.Component{
    render() {
        const content = this.getContent()
        return content
    }

    getContent(){
        return <StockListContainer />
    }
}