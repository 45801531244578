import React from "react";
import AsideContainer from "../../components/Aside/AsideContainer";
import ItemsList from "../../components/ItemsList/ItemsList";
import * as ItemNuevo from "../../components/ItemNuevo/ItemNuevo";
import CombinacionList from "../../components/CombinacionList/CombinacionList";
import CombinacionNuevo from "../../components/CombinacionNuevo/CombinacionNuevo";

export default class ItemsView extends React.Component {
  render() {
    return (
      <div>
        {this.getAside()}
        {this.getContent()}
      </div>
    );
  }

  getContent() {
    let content = "";
    if (!this.props.idItem) {
      content = (
        <ItemsList
          onClickNuevo={this.props.onClickNuevoItem}
          onDoubleClickRow={this.props.onDoubleClickRowItem}
        />
      );
    } else {
      content = (
        <CombinacionList
          idItem={this.props.idItem}
          onClickNuevo={this.props.onClickNuevoCombinacion}
          onDoubleClickRow={this.props.onDoubleClickRowCombinacion}
        />
      );
    }
    return content;
  }

  getAside() {
    let aside = "";
    switch (this.props.aside) {
      case "nuevo-item":
        aside = (
          <AsideContainer title="Nuevo Item">
            <ItemNuevo.Component onSubmit={this.props.onSubmitItemNuevo} />
          </AsideContainer>
        );
        break;
      case "nueva-combinacion":
        aside = (
          <AsideContainer title="Nueva Combinacion">
            <CombinacionNuevo
              idItem={this.props.idItem}
              idCombinacion={this.props.idCombinacion}
              onSubmit={this.props.onSubmitCombinacionNuevo}
            />
          </AsideContainer>
        );
        break;

      default:
        break;
    }
    return aside;
  }
}
