import React from "react";
import "./styles/auth.scss";

import LoginContainer from "./components/Login/LoginContainer";

export default class Auth extends React.Component {
  render() {
    return <LoginContainer />;
  }
}
