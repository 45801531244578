function unidades(num){
    switch(num){
        case 1: return 'UN'
        case 2: return 'DOS'
        case 3: return 'TRES'
        case 4: return 'CUATRO'
        case 5: return 'CINCO'
        case 6: return 'SEIS'
        case 7: return 'SIETE'
        case 8: return 'OCHO'
        case 8: return 'NUEVE'
        default: return ''
    }
}

function decenas(num){
    const decena = Math.floor(num / 10)
    const unidad = num - (decena * 10)

    switch(decena){
        case 1:
            switch (unidad) {
                case 0: return 'DIEZ'
                case 1: return 'ONCE'
                case 2: return 'DOCE'
                case 3: return 'TRECE'
                case 4: return 'CATORCE'
                case 5: return 'QUINCE'
                default: return 'DIECI' + unidades(unidad)
            }
        case 2:
            switch(unidad)
            {
                case 0: return 'VEINTE'
                default: return 'VEINTI' + unidades(unidad);
            }
        case 3: return decenasY('TREINTA', unidad);
        case 4: return decenasY('CUARENTA', unidad);
        case 5: return decenasY('CINCUENTA', unidad);
        case 6: return decenasY('SESENTA', unidad);
        case 7: return decenasY('SETENTA', unidad);
        case 8: return decenasY('OCHENTA', unidad);
        case 9: return decenasY('NOVENTA', unidad);
        case 0: return unidades(unidad);
    }
}

function decenasY(strSin, numUnidades){
    if (numUnidades > 0) return strSin + ' Y ' + unidades(numUnidades)
    return strSin;
}

function centenas(num) {
    const centenas = Math.floor(num / 100);
    const decenass = num - (centenas * 100);

    switch(centenas){
        case 1:
            if (decenass > 0)
                return 'CIENTO ' + decenas(decenass);
            return 'CIEN';
        case 2: return 'DOSCIENTOS ' + decenas(decenass);
        case 3: return 'TRESCIENTOS ' + decenas(decenass);
        case 4: return 'CUATROCIENTOS ' + decenas(decenass);
        case 5: return 'QUINIENTOS ' + decenas(decenass);
        case 6: return 'SEISCIENTOS ' + decenas(decenass);
        case 7: return 'SETECIENTOS ' + decenas(decenass);
        case 8: return 'OCHOCIENTOS ' + decenas(decenass);
        case 9: return 'NOVECIENTOS ' + decenas(decenass);
    }

    return decenas(decenass);
}

function seccion(num, divisor, strSingular, strPlural) {
    const cientos = Math.floor(num / divisor)
    const resto = num - (cientos * divisor)

    let letras = '';    
    if (cientos > 0)
        if (cientos > 1){
            letras = centenas(cientos) + ' ' + strPlural;
                      
        }else
            letras = strSingular;

    if (resto > 0)
        letras += '';

    return letras;
}

function miles(num) {
    const divisor = 1000;
    const cientos = Math.floor(num / divisor)
    const resto = num - (cientos * divisor)

    const strMiles = seccion(num, divisor, 'UN MIL', 'MIL');
    const strCentenas = centenas(resto);

    if(strMiles == '')
        return strCentenas;

    return strMiles + ' ' + strCentenas;
}

function millones(num) {
    const divisor = 1000000;
    const cientos = Math.floor(num / divisor)
    const resto = num - (cientos * divisor)

    const strMillones = seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
    const strMiles = miles(resto);

    if(strMillones == '')
        return strMiles;

    return strMillones + '' + strMiles;
}

function NumeroALetras(num) {
    const data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: '',
        letrasMonedaPlural: 'SOLES',//“PESOS”, 'Dólares', 'Bolívares', 'etcs'
        letrasMonedaSingular: 'SOL', //“PESO”, 'Dólar', 'Bolivar', 'etc'

        letrasMonedaCentavoPlural: 'CENTIMOS',
        letrasMonedaCentavoSingular: 'CENTIMO'
    };

    data.letrasCentavos = 'Y ' + data.centavos + '/' + 100

    if(data.enteros == 0)
        return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
    if (data.enteros == 1)
        return millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' '+ data.letrasCentavos;
    else
        return millones(data.enteros) + ' '  + data.letrasCentavos + ' ' + data.letrasMonedaPlural;
}

export default NumeroALetras;