import * as Axios from "../libs/Axios/Axios";

const path = "/movimiento-almacen";

export default class MovimientoAlmacenMotivo {
  static add(movimiento) {
    const params = { ...movimiento, items: JSON.stringify(movimiento.items) };
    return Axios.post(path, params);
  }

  static list() {
    return Axios.get(path);
  }

  static get(id_movimiento) {
    return Axios.get(path + `/${id_movimiento}`);
  }
}
