import React from "react";
import Contrato from "./Contrato";
import Cotizacion from "../../services/Cotizacion";
import Persona from "../../services/Persona";
import Empresa from "../../services/Empresa";
import { generateDocument } from "../../helpers/DocxTemplater";
import Letras from "../../helpers/Letras";

export default class ContratoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contrato: {
        id_contacto: "",
        tipo_contrato: "",
        partida: "",
        fianza: "",
        fecha_inicio_actual: "",
        fecha_fin_actual: "",
        fecha_inicio: "",
        fecha_fin: "",
      },
      contactos: [],
    };
    this.getOptions();
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleChangeTipoContrato = this.handleChangeTipoContrato.bind(this);
    this.handleChangeContacto = this.handleChangeContacto.bind(this);
    this.handleChangePartida = this.handleChangePartida.bind(this);
    this.handleChangeFianza = this.handleChangeFianza.bind(this);
    this.handleChangeFechaInicio = this.handleChangeFechaInicio.bind(this);
    this.mostrarContactos();
    this.capturarEmpresa();
  }
  render() {
    return (
      <Contrato
        onChangeTipoContrato={this.handleChangeTipoContrato}
        onChangeContacto={this.handleChangeContacto}
        onSubmitModal={this.handleSubmitModal}
        onChangePartida={this.handleChangePartida}
        onChangeFianza={this.handleChangeFianza}
        onChangeFechaInicio={this.handleChangeFechaInicio}
        onHideModal={this.props.onHideModal}
        contactos={this.state.contactos}
        contrato={this.state.contrato}
      />
    );
  }

  // handleSubmitModal() {
  //   window.open(
  //     `http://localhost:3000/cotizacion/descarga?id_cotizacion=${
  //       this.props.cotizacion.id_cotizacion ||
  //       this.props.cotizacion.items[0].id_cotizacion
  //     }&opciones=${JSON.stringify(this.state.opciones_select)}`,
  //     "_blank"
  //   );
  //   // this.props.onSubmitModal();
  // }

  handleCheckBoxChange(evt) {
    const id = Number(evt.target.getAttribute("data-id"));

    if (evt.target.checked) {
      const opcion = this.state.opciones.find((elm) => elm.id === id);
      this.setState({
        opciones_select: [...this.state.opciones_select, opcion],
      });
    } else {
      const idx = this.state.opciones_select.findIndex((elm) => elm.id === id);
      this.state.opciones_select.splice(idx, 1);
      this.setState({
        opciones_select: this.state.opciones_select,
      });
    }
  }

  handleChangeTipoContrato(evt) {
    this.setState({
      contrato: {
        ...this.state.contrato,
        tipo_contrato: evt.target.value,
      },
    });
  }

  handleChangeContacto(evt) {
    this.setState({
      contrato: {
        ...this.state.contrato,
        id_contacto: evt.target.value,
      },
    });
  }

  handleChangePartida(evt) {
    this.setState({
      contrato: {
        ...this.state.contrato,
        partida: evt.target.value,
      },
    });
  }

  handleChangeFianza(evt) {
    this.setState({
      contrato: {
        ...this.state.contrato,
        fianza: evt.target.value,
      },
    });
  }

  handleChangeFechaInicio(evt) {
    const d = new Date(evt.target.value);

    const date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const year = d.getFullYear() + 1;
    const month =
      d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;

    const nextYearDate = `${year}-${month}-${date}`;

    this.setState({
      contrato: {
        ...this.state.contrato,
        fecha_inicio: evt.target.value,
        fecha_fin: nextYearDate,
      },
    });
  }

  async getOptions() {
    const opciones = await Cotizacion.getOpciones();
    const opciones_select = opciones.map((elm) => elm);
    this.setState({
      opciones,
      opciones_select,
    });
  }

  async capturarEmpresa() {
    const empresa = await Empresa.get(this.props.cotizacion.id_persona);
    console.log("EMPRESA", empresa);
    this.setState({ empresa });
  }

  async mostrarContactos() {
    const { results: contactos } = await Persona.getContactos(
      this.props.cotizacion.id_persona
    );
    this.setState({
      contactos,
    });
  }

  async handleSubmitModal() {
    const date_inicio = new Date(this.state.contrato.fecha_inicio);
    const date_fin = new Date(this.state.contrato.fecha_fin);
    const hoy = new Date();

    const { results: cotizacion } = await Cotizacion.get(
      this.props.cotizacion.id_cotizacion
    );

    const ubicaciones = cotizacion.map((elm) => {
      return {
        producto: elm.descripcion,
        ubicacion: elm.descripcion_ubicacion,
        cantidad: elm.cantidad,
        direccion: elm.direccion ? elm.direccion.capitalize() : " - ",
      };
    });

    let productos = [];

    cotizacion.forEach((elm) => {
      let find = false;

      productos.forEach((item, idx) => {
        if ((elm.id_item === item.id_item) & (elm.armado === item.armado)) {
          productos[idx] = {
            ...productos[idx],
            cantidad: productos[idx].cantidad + elm.cantidad,
            importe: (
              (productos[idx].cantidad + elm.cantidad) *
              elm.precio
            ).toFixed(2),
          };

          find = true;
        }
      });

      if (!find) {
        productos.push({
          id_item: elm.id_item,
          producto: elm.descripcion,
          turno: "Día / Noche",
          precio: elm.precio.toFixed(2),
          cantidad: elm.cantidad,
          importe: (elm.cantidad * elm.precio).toFixed(2),
        });
      }
    });

    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const contacto = this.state.contactos.find(
      (elm) => elm.id_persona_contacto == this.state.contrato.id_contacto
    );

    generateDocument(
      "https://avp.pe/wp-content/uploads/files/contrato.docx",
      "contrato",
      {
        razon_social: cotizacion[0].razon_social,
        ruc: cotizacion[0].ruc,
        domicilio: `${contacto.direccion}, DISTRITO DE ${this.state.empresa.distrito}, PROVINCIA DE ${this.state.empresa.provincia} Y DEPARTAMENTO DE ${this.state.empresa.departamento}`,
        contacto_relacion: contacto.relacion.toUpperCase(),
        contacto_nombre: contacto.nombre_completo.toUpperCase(),
        contacto_tipo_documento: contacto.abreviatura,
        contacto_documento: contacto.documento,
        partida: this.state.contrato.partida,
        ubicacion: contacto.ubicacion
          ? contacto.ubicaicon.toUpperCase()
          : " - ",
        desde: date_inicio.toLocaleDateString("es-PE", options),
        hasta: date_fin.toLocaleDateString("es-PE", options),
        hoy: hoy.toLocaleDateString("es-PE", options),
        actividad_comercial: this.state.empresa.actividad_comercial,
        fianza: this.state.contrato.fianza,
        fianza_str: Letras(this.state.contrato.fianza),
      }
    );

    // const total = productos.reduce((A, B) => A + B.importe, 0);

    // let data = {
    //   razon_social: this.state.negocio.razon_social,
    //   ruc: this.state.negocio.ruc,
    //   domicilio: `${contacto.direccion}, DISTRITO DE ${contacto.distrito}, PROVINCIA DE ${contacto.provincia} Y DEPARTAMENTO DE ${contacto.departamento}`,
    //   contacto_nombre: contacto.nombre_completo.toUpperCase(),
    //   contacto_tipo_documento: contacto.abreviatura,
    //   contacto_documento: contacto.documento,
    //   desde: date_inicio.toLocaleDateString("es-PE", options),
    //   hasta: date_fin.toLocaleDateString("es-PE", options),
    //   hoy: hoy.toLocaleDateString("es-PE", options),
    //   ubicaciones,
    //   productos,
    //   distrito: cotizacion[0].distrito,
    //   ubicacion:
    //     cotizacion[0].direccion.capitalize() + ", " + cotizacion[0].distrito,
    //   total: parseFloat(total).toFixed(2),
    //   anual: (total * 12).toFixed(2),
    // };

    // let url_anexos = "https://avp.pe/wp-content/uploads/files/anexos3.docx";

    // if (this.state.contrato.tipo_contrato !== 1) {
    //   url_anexos = "https://avp.pe/wp-content/uploads/files/anexos-adenda.docx";
    //   const date_inicio_actual = new Date(
    //     this.state.contrato.fecha_inicio_actual
    //   );
    //   const date_hasta_actual = new Date(this.state.contrato.fecha_fin_actual);

    //   data["desde_actual"] = date_inicio_actual.toLocaleDateString(
    //     "es-PE",
    //     options
    //   );
    //   data["hasta_actual"] = date_hasta_actual.toLocaleDateString(
    //     "es-PE",
    //     options
    //   );
    // }

    // generateDocument(url_anexos, "anexos", data);
  }
}
