import React from "react";
import { ReactComponent as CerrarIcon } from "../../images/Cerrar.svg";

class Aside extends React.Component {
  render() {
    return (
      <aside className={!this.props.open ? "closed" : ""}>
        <div className="aside-container">
          <button
            className={"btn" + (this.props.closeBtn === false ? " d-none" : "")}
            onClick={this.props.onClickCerrar}
          >
            <CerrarIcon />
          </button>
          <div
            className={
              "aside-title-block" +
              (this.props.closeBtn === false ? " mt-4" : "")
            }
          >
            <h4 className="fw-normal">{this.props.title}</h4>
          </div>
          <div className="aside-content-block">{this.props.children}</div>
        </div>
      </aside>
    );
  }
}

export { Aside as default };
