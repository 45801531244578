import * as Axios from "../libs/Axios/Axios";

const path = "/persona";

export default class Persona {
  static async getTipoDocumentoIdentidad() {
    return Axios.get(`${path}/tipo-documento-identidad`);
  }

  static async getPersonaRelacion() {
    return Axios.get(`${path}/relacion`);
  }

  static async getContactos(id_persona) {
    return Axios.get(`${path}/contacto?id_persona=${id_persona}`);
  }

  static async getContacto(id_contacto) {
    return Axios.get(`${path}/contacto/${id_contacto}`);
  }

  static async getContactosPorNegocio(id_negocio) {
    return Axios.get(`${path}/contacto-por-negocio?id_negocio=${id_negocio}`);
  }
}
