import React from "react";
import ContactoNuevo from "./ContactoNuevo";
import Persona from "../../services/Persona";
import Empresa from "../../services/Empresa";
import Ubicacion from "../../services/Ubicacion";
import { addNotification } from "../Notifications/Notifications";

export default class ContactoNuevoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacto: {
        id_persona_relacion: undefined,
        id_tipo_documento_identidad: undefined,
        documento: undefined,
        nombres: undefined,
        ap_paterno: undefined,
        ap_materno: undefined,
        telefono: undefined,
        email: undefined,
        fecha_nacimiento: undefined,
        id_departamento_domicilio: undefined,
        id_provincia_domicilio: undefined,
        id_distrito_domicilio: undefined,
        id_tipo_via_domicilio: undefined,
        via_domicilio: undefined,
        numeracion_via_domicilio: undefined,
        direccion_domicilio: undefined,
      },
      relaciones: [],
      tipos_documento_identidad: [],
      departamentos: [],
      provincias: [],
      distritos: [],
      tipos_via: [],
    };
    this.init();
  }

  render() {
    return (
      <ContactoNuevo
        contacto={this.state.contacto}
        relaciones={this.state.relaciones}
        tipos_documento_identidad={this.state.tipos_documento_identidad}
        departamentos={this.state.departamentos}
        provincias={this.state.provincias}
        distritos={this.state.distritos}
        tipos_via={this.state.tipos_via}
        onClickAtras={this.props.onClickAtras}
        onChangeRelacion={this.handleChangeRelacion}
        onChangeTipoDocumentoIdentidad={this.handleChangeTipoDocumentoIdentidad}
        onChangeDocumento={this.handleChangeDocumento}
        onChangeNombres={this.handleChangeNombres}
        onChangeApPaterno={this.handleChangeApPaterno}
        onChangeApMaterno={this.handleChangeApMaterno}
        onChangeTelefono={this.handleChangeTelefono}
        onChangeEmail={this.handleChangeEmail}
        onChangeFechaNacimiento={this.handleChangeFechaNacimiento}
        onChangeDepartamento={this.handleChangeDepartamento}
        onChangeProvincia={this.handleChangeProvincia}
        onChangeDistrito={this.handleChangeDistrito}
        onChangeTipoVia={this.handleChangeTipoVia}
        onChangeVia={this.handleChangeVia}
        onChangeNumeracionVia={this.handleChangeNumeracionVia}
        onChangeDireccionDomicilio={this.handleChangeDireccionDomicilio}
        onClickGuardar={this.handleClickGuardar}
      />
    );
  }

  init() {
    this.mostrarRelaciones();
    this.mostrarTiposDocumentoIdentidad();
    this.mostrarDepartamentos();
    this.mostrarTiposVia();
    this.handleChangeRelacion = this.handleChangeRelacion.bind(this);
    this.handleChangeTipoDocumentoIdentidad =
      this.handleChangeTipoDocumentoIdentidad.bind(this);
    this.handleChangeDocumento = this.handleChangeDocumento.bind(this);
    this.handleChangeNombres = this.handleChangeNombres.bind(this);
    this.handleChangeApPaterno = this.handleChangeApPaterno.bind(this);
    this.handleChangeApMaterno = this.handleChangeApMaterno.bind(this);
    this.handleChangeTelefono = this.handleChangeTelefono.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeFechaNacimiento =
      this.handleChangeFechaNacimiento.bind(this);
    this.handleChangeDepartamento = this.handleChangeDepartamento.bind(this);
    this.handleChangeProvincia = this.handleChangeProvincia.bind(this);
    this.handleChangeDistrito = this.handleChangeDistrito.bind(this);
    this.handleChangeTipoVia = this.handleChangeTipoVia.bind(this);
    this.handleChangeVia = this.handleChangeVia.bind(this);
    this.handleChangeNumeracionVia = this.handleChangeNumeracionVia.bind(this);
    this.handleChangeDireccionDomicilio =
      this.handleChangeDireccionDomicilio.bind(this);
    this.handleClickGuardar = this.handleClickGuardar.bind(this);
    this.getContacto();
  }

  handleChangeRelacion(id_persona_relacion) {
    this.setState({
      contacto: {
        ...this.state.contacto,
        id_persona_relacion,
      },
    });
  }

  handleChangeTipoDocumentoIdentidad(id_tipo_documento_identidad) {
    this.setState({
      contacto: {
        ...this.state.contacto,
        id_tipo_documento_identidad,
      },
    });
  }

  handleChangeDocumento(evt) {
    this.setState({
      contacto: { ...this.state.contacto, documento: evt.target.value },
    });
  }

  handleChangeNombres(evt) {
    this.setState({
      contacto: { ...this.state.contacto, nombres: evt.target.value },
    });
  }

  handleChangeApPaterno(evt) {
    this.setState({
      contacto: { ...this.state.contacto, ap_paterno: evt.target.value },
    });
  }

  handleChangeApMaterno(evt) {
    this.setState({
      contacto: { ...this.state.contacto, ap_materno: evt.target.value },
    });
  }

  handleChangeTelefono(evt) {
    this.setState({
      contacto: { ...this.state.contacto, telefono: evt.target.value },
    });
  }

  handleChangeEmail(evt) {
    this.setState({
      contacto: { ...this.state.contacto, email: evt.target.value },
    });
  }

  handleChangeFechaNacimiento(evt) {
    this.setState({
      contacto: { ...this.state.contacto, fecha_nacimiento: evt.target.value },
    });
  }

  async handleChangeDepartamento(id_departamento) {
    const { results: provincias } = await Ubicacion.provinciasList(
      id_departamento
    );
    this.setState({
      provincias,
      contacto: {
        ...this.state.contacto,
        id_departamento_domicilio: id_departamento,
      },
    });
  }

  async handleChangeProvincia(id_provincia) {
    const { results: distritos } = await Ubicacion.distritosList(id_provincia);
    this.setState({
      distritos,
      contacto: {
        ...this.state.contacto,
        id_provincia_domicilio: id_provincia,
      },
    });
  }

  async handleChangeDistrito(id_distrito) {
    this.setState({
      contacto: { ...this.state.contacto, id_distrito_domicilio: id_distrito },
    });
  }

  async handleChangeTipoVia(id_tipo_via) {
    this.setState({
      contacto: { ...this.state.contacto, id_tipo_via_domicilio: id_tipo_via },
    });
  }

  async handleChangeVia(evt) {
    this.setState({
      contacto: { ...this.state.contacto, via_domicilio: evt.target.value },
    });
  }

  async handleChangeNumeracionVia(evt) {
    this.setState({
      contacto: {
        ...this.state.contacto,
        numeracion_via_domicilio: evt.target.value,
      },
    });
  }

  async handleChangeDireccionDomicilio(evt) {
    this.setState({
      contacto: {
        ...this.state.contacto,
        direccion_domicilio: evt.target.value,
      },
    });
  }

  async mostrarRelaciones() {
    const { results: relaciones } = await Persona.getPersonaRelacion();
    this.setState({ relaciones });
  }

  async mostrarTiposDocumentoIdentidad() {
    const { results: tipos_documento_identidad } =
      await Persona.getTipoDocumentoIdentidad();
    this.setState({ tipos_documento_identidad });
  }

  async mostrarDepartamentos() {
    const { results: departamentos } = await Ubicacion.departamentosList();
    this.setState({ departamentos });
  }

  async mostrarTiposVia() {
    const { results: tipos_via } = await Ubicacion.tiposViaList();
    this.setState({ tipos_via });
  }

  async getContacto() {
    const { results } = await Persona.getContacto(
      this.props.id_contacto_to_edit
    );
    this.setState({
      contacto: {
        id_persona_contacto: results[0].id_persona_contacto,
        id_persona_relacion: results[0].id_persona_relacion,
        id_tipo_documento_identidad: results[0].id_tipo_documento_identidad,
        documento: results[0].documento,
        nombres: results[0].nombres,
        ap_paterno: results[0].ap_paterno,
        ap_materno: results[0].ap_materno,
        telefono: results[0].telefono,
        email: results[0].email,
        fecha_nacimiento: results[0].fecha_nacimiento,
        id_departamento_domicilio: results[0].id_departamento,
        id_provincia_domicilio: results[0].id_provincia,
        id_distrito_domicilio: results[0].id_distrito,
        id_tipo_via_domicilio: results[0].id_tipo_via,
        via_domicilio: results[0].via,
        numeracion_via_domicilio: results[0].numeracion,
        direccion_domicilio: results[0].direccion,
      },
    });
    console.log(results);
  }

  async handleClickGuardar() {
    try {
      if (this.state.contacto.id_persona_contacto) {
        await Empresa.contactoUpdate({
          ...this.state.contacto,
          id_empresa: this.props.idEmpresa,
        });
      } else {
        await Empresa.contactoAdd({
          ...this.state.contacto,
          id_empresa: this.props.idEmpresa,
        });
      }
      this.props.onSubmit();
    } catch (ex) {
      addNotification("Error al guardar contacto", "bg-danger");
    }
  }
}
