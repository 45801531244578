import React from "react";
import SelectSearchContainer from "../SelectSearch/SelectSearchContainer";

export default class CombinacionAniadir extends React.Component {
  render() {
    return (
      <div className="ps-4 pe-4 pt-3">
        <div className="form-group mb-2 position-relative">
          <SelectSearchContainer
            options={this.props.modos}
            id="modo"
            valueKey="id"
            displayKey="descripcion"
            value={this.props.id_modo}
            onChange={this.props.onChangeModo}
          />
        </div>
        {this.props.id_modo === 1
          ? this.getProductosForm()
          : this.getPackForm()}
      </div>
    );
  }

  getPackForm() {
    return (
      <div>
        <div className="form-group mb-2 position-relative">
          <label>Packs</label>
          <SelectSearchContainer
            options={this.props.packs}
            id="grupo_item"
            valueKey="id_grupo_item"
            displayKey="descripcion"
            onChange={this.props.onChangePack}
          />
        </div>
      </div>
    );
  }

  getProductosForm() {
    const iptCombinaciones = this.getIptAtributos();
    const iptPrecio = !this.props.hiddenPrice ? (
      <div className="form-group mb-2 position-relative">
        <label>Precio</label>
        <input
          type="number"
          className="form-control"
          value={this.props.precio}
          onChange={this.props.onChangePrecio}
        />
      </div>
    ) : (
      ""
    );

    return (
      <div>
        <div className="form-group mb-2 position-relative">
          <label>Producto</label>
          <SelectSearchContainer
            options={this.props.productos}
            id="producto"
            valueKey="id_item"
            displayKey="descripcion"
            onChange={this.props.onChangeProducto}
          />
        </div>
        {iptCombinaciones}
        <div className="form-group mb-2 position-relative">
          <label>Cantidad</label>
          <input
            type="number"
            className="form-control"
            value={this.props.cantidad}
            onChange={this.props.onChangeCantidad}
          />
        </div>
        {iptPrecio}
        <div className="aside-footer position-fixed end-0 bottom-0 p-3">
          <button
            className="btn btn-primary rounded-pill"
            onClick={this.props.onSubmit}
          >
            Añadir
          </button>
        </div>
      </div>
    );
  }

  getIptAtributos() {
    return this.props.atributos.map((elm) => {
      if (elm.id_atributo !== null && elm.id_atributo !== undefined) {
        return (
          <div
            className="form-group mb-2 position-relative"
            key={`cont-attr-${elm.id_atributo}`}
          >
            <label>{elm.atributo}</label>
            <SelectSearchContainer
              options={elm.options}
              id={`attr-${elm.id_atributo}`}
              valueKey="id_valor"
              displayKey="valor"
              onChange={this.props.onChangeAtributo}
            />
          </div>
        );
      } else {
        return "";
      }
    });
  }
}
