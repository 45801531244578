import * as Axios from '../libs/Axios/Axios'

const path = '/orden-compra'

export default class OrdenCompra{

    static add(id_proveedor, orden_compra){
        return Axios.post(path, {id_proveedor, orden_compra})
    }

    static list(){
        return Axios.get(path)
    }

    static get(id_orden_compra){
        return Axios.get(path + `/${id_orden_compra}`)
    }
}