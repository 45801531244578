import React from "react";
import TabPaneContainer from "../TabPane/TabPaneContainer";

export default class CotizacionNuevo extends React.Component {
  render() {
    return (
      <div>
        <TabPaneContainer
          items={this.props.items}
          active={this.props.active}
          onClickItem={this.props.onClickItem}
        ></TabPaneContainer>
      </div>
    );
  }
}
