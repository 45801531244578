import React from "react";
import Producto from "../../services/Producto";
import ItemsListView from "./ItemsListView";
import { subscribe } from "../../components/ItemNuevo/ItemNuevo";

export default class ItemsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: "",
      items: [],
      itemsVisibles: [],
    };
    this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this);
    this.getItems = this.getItems.bind(this);
    subscribe(this.getItems);
    this.getItems();
  }

  render() {
    return (
      <ItemsListView
        items={this.state.itemsVisibles}
        onChangeSearchWord={this.handleChangeSearchWord}
        onClickNuevo={this.props.onClickNuevo}
        onDoubleClickRow={this.props.onDoubleClickRow}
      />
    );
  }

  handleChangeSearchWord(evt) {
    this.setState(
      {
        searchWord: evt.target.value,
      },
      () => this.buscarItems()
    );
  }

  async getItems() {
    const { results: items } = await Producto.list();
    this.setState(
      {
        items,
      },
      () => {
        this.buscarItems();
      }
    );
  }

  buscarItems() {
    let itemsVisibles = [];

    if (this.state.searchWord.trim() === "") {
      itemsVisibles = this.state.items;
    } else {
      const word = this.state.searchWord.trim().toUpperCase();
      itemsVisibles = this.state.items.filter(
        (item) =>
          String(item.id_item) === word ||
          item.descripcion.toUpperCase().includes(word)
      );
    }

    this.setState({
      itemsVisibles,
    });
  }
}
