import * as Axios from '../libs/Axios/Axios'

const path = '/trabajador'

export default class Trabajador{

    static list(){
        return Axios.get(path)
    }
    
}