import React from "react";
import CotizacionCheck from "./CotizacionCheck";
import Cotizacion from "../../services/Cotizacion";

export default class CotizacionCheckContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opciones: [],
      opciones_select: [],
    };
    this.getOptions();
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    console.log(this.props.cotizacion);
  }
  render() {
    return (
      <CotizacionCheck
        onSubmitModal={this.handleSubmitModal}
        opciones={this.state.opciones}
        opciones_select={this.state.opciones_select}
        onCheckBoxChange={this.handleCheckBoxChange}
      />
    );
  }

  handleSubmitModal() {
    window.open(
      `https://ws.avp.pe/cotizacion/descarga?id_cotizacion=${
        this.props.cotizacion.id_cotizacion ||
        this.props.cotizacion.items[0].id_cotizacion
      }&opciones=${JSON.stringify(this.state.opciones_select)}`,
      "_blank"
    );
    this.props.onSubmitModal();
  }

  handleCheckBoxChange(evt) {
    const id = Number(evt.target.getAttribute("data-id"));

    if (evt.target.checked) {
      const opcion = this.state.opciones.find((elm) => elm.id === id);
      this.setState({
        opciones_select: [...this.state.opciones_select, opcion],
      });
    } else {
      const idx = this.state.opciones_select.findIndex((elm) => elm.id === id);
      this.state.opciones_select.splice(idx, 1);
      this.setState({
        opciones_select: this.state.opciones_select,
      });
    }
  }

  async getOptions() {
    const opciones = await Cotizacion.getOpciones();
    const opciones_select = opciones.map((elm) => elm);
    // this.state.opciones = opciones;
    this.setState({
      opciones,
      opciones_select,
    });
  }
}
